#technical-specs {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
#technical-specs header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#technical-specs .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#technical-specs .row .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

@media (min-width: 500px) {
  #technical-specs .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #technical-specs .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
