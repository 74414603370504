#c-hero {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#c-hero .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem 1rem 1rem;
}
#c-hero .header > h1 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
#c-hero .header .sort-div {
  display: none;
}
#c-hero .container {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  padding: 0 1rem;
}
#c-hero .container .left {
  display: none;
  width: 22.5rem;
  padding: 2rem 1.25rem;
  border-radius: 0.75rem;
  height: fit-content;
  border: 2px solid #28282b25;
  background-color: var(--primary-50);
}
#c-hero .container .left form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
#c-hero .container .left form .filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.25rem;
}
#c-hero .container .left form .solid {
  border-bottom: 2px solid #28282b25;
  border-radius: 0.5rem;
}
#c-hero .container .left form .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  color: #28282b;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
}
#c-hero .container .left form .year,
#c-hero .container .left form .price {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 0.5rem;
  row-gap: 1.25rem;
}
#c-hero .container .left form .clear-search {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  align-self: flex-start;
  color: var(--primary-900);
  font-size: 1rem;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid var(--primary-900);
  padding: 0.75rem 1rem;
  border-radius: var(--radius-8);
  cursor: pointer;
  width: 100%;
}
#c-hero .container .left form .clear-search:hover {
  box-shadow: inset 0px 0px 0px 0.5px var(--primary-900);
}

#c-hero .container .right {
  width: 100%;
}
#c-hero .sale {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#c-hero .sale .listings-range {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  color: var(--primary-900);
  font-size: 1.125rem;
  line-height: 1.25;
  align-self: flex-start;
  margin-bottom: 1rem;
}
#c-hero .sale .no-results {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
#c-hero .sale .no-results p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  color: var(--primary-900);
  font-size: 1.25rem;
  line-height: 1.25;
}
#c-hero .sale .no-results span {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  color: var(--primary-900);
  font-size: 1.125rem;
  line-height: 1.25;
}
#c-hero .sale .no-results button {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  align-self: flex-start;
  color: var(--primary-900);
  font-size: 1rem;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid var(--primary-900);
  padding: 0.75rem 1rem;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#c-hero .sale .no-results button:hover {
  box-shadow: inset 0px 0px 0px 0.5px var(--primary-900);
}
#c-hero .sale .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}

/* pagination */
#c-hero .sale .paginationBtns {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-top: 2rem;
}

#c-hero .sale .paginationBtns a {
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-300);
  color: var(--primary-500);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
#c-hero .sale .paginationBtns .next a,
#c-hero .sale .paginationBtns .previous a {
  width: auto;
}
#c-hero .sale .paginationBtns a:hover {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}
#c-hero .sale .paginationActive a {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}

/* Mobile UI for Header */
#c-hero .mobile {
  display: flex;
  width: 100%;
  position: sticky;
  top: 4rem;
  z-index: 49;
}
#c-hero .mobile .btns {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-50);
}
#c-hero .mobile .btns button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  width: 50%;
  height: 3rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  background: #fff;
  color: var(--primary-900);
  border: none;
  cursor: pointer;
}

/* Mobile UI for Search */
.cars-mobile-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8rem;
  background-color: var(--primary-50);
  position: fixed;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
}
.cars-mobile-search > div:nth-child(1) {
  width: 100%;
  height: 4.5rem;
  box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgb(1 1 1 / 10%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: fixed;
  background-color: #fff;
  z-index: 99;
  transition: box-shadow 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
}
.cars-mobile-search > div:nth-child(1) h2 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  color: var(--primary-900);
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.25;
}
.cars-mobile-search > div:nth-child(1) button {
  border: none;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--primary-50);
  display: flex;
  cursor: pointer;
}
.cars-mobile-search > div:nth-child(1) button:hover {
  background-color: var(--primary-200);
}
.cars-mobile-search form {
  display: flex;
  flex-direction: column;
  padding: 5rem 1rem;
  width: 100%;
}
.cars-mobile-search form .filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.25rem;
  margin-top: 2rem;
}
.cars-mobile-search form .solid {
  border-bottom: 2px solid #28282b25;
  border-radius: 0.5rem;
}

.cars-mobile-search form .year,
.cars-mobile-search form .price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.5rem;
  row-gap: 1.25rem;
  width: 100%;
}
.cars-mobile-search > div:nth-child(3) {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: var(--primary-50);
  position: fixed;
  padding: 0rem 1rem;
  bottom: 0;
  box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgb(1 1 1 / 30%);
  height: 5rem;
}
.cars-mobile-search > div:nth-child(3) button {
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  color: #fff;
  background-color: #28282b;
  border: 2px solid #28282b;
  border-radius: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.cars-mobile-search > div:nth-child(3) button:hover {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
}

@media (min-width: 640px) {
  .cars-mobile-search > div:nth-child(1) {
    padding: 0 2rem;
  }
  .cars-mobile-search form {
    padding: 5rem 2rem;
  }
  .cars-mobile-search > div:nth-child(3) {
    padding: 0 2rem;
  }
  #c-hero .header {
    padding: 2rem 2rem 1rem 2rem;
  }
  #c-hero .header > h1 {
    font-size: 1.875rem;
  }
  #c-hero .container {
    padding: 0 2rem;
  }
  #c-hero .container .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #c-hero .sale .paginationBtns {
    column-gap: 0.75rem;
  }
  #c-hero .sale .paginationBtns a {
    padding: 0.75rem;
    height: 2.5rem;
    width: 2.5rem;
  }
}
@media (min-width: 768px) {
  .cars-mobile-search > div:nth-child(1) {
    padding: 0 2.5rem;
  }
  .cars-mobile-search form {
    padding: 5rem 2.5rem;
  }
  .cars-mobile-search > div:nth-child(3) {
    padding: 0 2.5rem;
  }
  #c-hero {
    margin-top: 5rem;
  }
  #c-hero .header {
    padding: 2rem 2.5rem 1rem 2.5rem;
  }
  #c-hero .header > h1 {
    font-size: 2rem;
  }
  #c-hero .container {
    padding: 0 2.5rem;
  }
  #c-hero .container .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #c-hero .mobile {
    top: 5rem;
  }
}
@media (min-width: 900px) {
  #c-hero .container .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  #c-hero .mobile {
    display: none;
  }
  .cars-mobile-search {
    display: none;
  }
  #c-hero .header {
    padding: 2rem 3rem;
  }
  #c-hero .header .sort-div {
    display: initial;
  }
  #c-hero .filter-row {
    display: none;
  }
  #c-hero .container {
    padding: 0 3rem;
  }
  #c-hero .container .left {
    display: initial;
  }
  #c-hero .container .right {
    width: 73%;
  }
  #c-hero .container .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  #c-hero .header {
    max-width: 1280px;
  }
  #c-hero .container {
    max-width: 1280px;
  }
  #c-hero .container .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  #c-hero .header {
    max-width: 1536px;
    padding: 2rem 5rem;
  }
  #c-hero .container {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #c-hero .container .left {
    width: 27%;
  }
}
