#s-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}
#s-hero .showroom-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
}
#s-hero .showroom-header h1 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.25;
}
#s-hero .showroom-header > div {
  display: none;
}
#s-hero .showroom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  gap: 2rem;
}
#s-hero .showroom .no-results {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  color: var(--primary-900);
  font-size: 1.25rem;
  line-height: 1.25;
  align-self: flex-start;
}

#s-hero .showroom .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}
/* pagination */
#s-hero .showroom .paginationBtns {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
#s-hero .showroom .paginationBtns a {
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-300);
  color: var(--primary-500);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
#s-hero .showroom .paginationBtns .next a,
#s-hero .showroom .paginationBtns .previous a {
  width: auto;
}
#s-hero .showroom .paginationBtns a:hover {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}
#s-hero .showroom .paginationActive a {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}

/* Mobile UI for Header */
#s-hero .mobile {
  display: flex;
  width: 100%;
  position: sticky;
  top: 4rem;
  z-index: 49;
  background: #fff;
}
#s-hero .mobile .btn {
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
#s-hero .mobile .btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  height: 3rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  background: #fff;
  color: var(--primary-900);
  border: none;
  cursor: pointer;
}

@media (min-width: 640px) {
  #s-hero .showroom-header {
    flex-direction: row;
    padding: 2rem 2rem;
  }
  #s-hero .showroom-header h1 {
    font-size: 1.875rem;
  }
  #s-hero .showroom {
    padding: 0 2rem;
  }
  #s-hero .showroom .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #s-hero .showroom .paginationBtns {
    column-gap: 0.75rem;
  }
  #s-hero .showroom .paginationBtns a {
    padding: 0.75rem;
    height: 2.5rem;
    width: 2.5rem;
  }
}
@media (min-width: 768px) {
  #s-hero {
    margin-top: 5rem;
  }
  #s-hero .mobile {
    top: 5rem;
  }
  #s-hero .showroom-header {
    padding: 2rem 2.5rem;
  }
  #s-hero .showroom-header h1 {
    font-size: 2rem;
  }
  #s-hero .showroom {
    padding: 0 2.5rem;
  }
  #s-hero .showroom .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  #s-hero .mobile {
    display: none;
  }
  #s-hero .showroom-header {
    padding: 2rem 3rem;
  }
  #s-hero .showroom-header > div {
    display: initial;
  }
  #s-hero .showroom {
    padding: 0 3rem;
  }
  #s-hero .showroom .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  #s-hero .showroom-header {
    max-width: 1280px;
  }
  #s-hero .showroom {
    max-width: 1280px;
  }
  #s-hero .showroom .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  #s-hero .showroom-header {
    max-width: 1536px;
    padding: 2rem 5rem;
  }
  #s-hero .showroom {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #s-hero .showroom .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
