#step-seven-add-car {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
#step-seven-add-car > header p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.375rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#step-seven-add-car .step-seven-btns {
  display: flex;
  justify-content: flex-end;
}
#step-seven-add-car .step-seven-btns .submit {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: var(--primary-900);
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#step-seven-add-car .step-seven-btns .submit:hover {
  background-color: var(--primary-800);
}
