#plate-table {
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
  border-radius: var(--radius-8);
  overflow-x: auto;
}
#plate-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}
#plate-table table thead {
  border-bottom: 1px solid var(--primary-200);
}

#plate-table table thead th {
  padding: 1rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  text-align: start;
  font-weight: 500;
}
#plate-table table tbody {
  overflow: scroll;
}
#plate-table table tbody tr {
  border-bottom: 1px solid var(--primary-50);
}
#plate-table table tbody tr td {
  padding: 1rem;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#plate-table .td-1 .plate-number {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  white-space: nowrap;
}
#plate-table .td-1 .plate-city {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#plate-table .price {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
}
#plate-table .status {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
  width: fit-content;
  border-radius: var(--radius-8);
  color: var(--primary-900);
  white-space: nowrap;
}
#plate-table .status.available {
  background-color: rgba(0, 255, 0, 0.08);
  color: var(--green-600);
}
#plate-table .status.sold {
  background-color: rgba(255, 0, 0, 0.05);
  color: var(--red-600);
}

#plate-table .action-btns {
  display: flex;
  column-gap: 0.5rem;
}
#plate-table .delete {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  line-height: 1.25;
  background-color: transparent;
  color: var(--red-600);
  border-radius: var(--radius-8);
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid var(--red-600);
}
#plate-table .edit {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  line-height: 1.25;
  background-color: transparent;
  color: var(--primary-500);
  border-radius: var(--radius-8);
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid var(--primary-500);
}
#plate-table .delete:hover {
  cursor: pointer;
  background-color: rgba(219, 15, 15, 0.05);
  color: var(--red-600);
}
#plate-table .edit:hover {
  cursor: pointer;
  background-color: var(--primary-50);
}
