#car-listings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 0.5rem;
}
#car-listings footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
#car-listings footer p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  white-space: nowrap;
}

#car-listings footer .paginationBtns {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

#car-listings footer .paginationBtns li a:hover svg path {
  fill: #fff;
}
[dir="rtl"] #car-listings footer .paginationBtns li a svg {
  transform: scaleX(-1);
}
#car-listings footer .paginationBtns a {
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-300);
  color: var(--primary-500);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
#car-listings footer .paginationBtns a:hover {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}
#car-listings footer .paginationActive a {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}

@media (min-width: 500px) {
  #car-listings footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
