#co-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  background: #28282b;
  color: #fff;
}
#co-hero .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 3rem 1rem 4rem 1rem;
}
#co-hero .container > h1 {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.25;
}
#co-hero .co-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#co-hero .container h2 {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
}
#co-hero .co-info div {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  line-height: 1.25;
}
#co-hero .co-info div p {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
}
#co-hero .co-info div a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
#co-hero .co-info div a:hover {
  border-bottom: 1px solid #fff;
}
#co-hero .account {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  line-height: 1.25;
}
#co-hero .account > p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
}
#co-hero .account div {
  display: flex;
  column-gap: 0.75rem;
}
#co-hero .account div a svg {
  transition: all 0.3s ease-in;
}
#co-hero .account div a:hover svg {
  transform: scale(1.1);
}

@media (min-width: 640px) {
  #co-hero .container {
    padding: 3rem 2rem 4rem 2rem;
  }
  #co-hero .container > p:first-child {
    font-size: 1.875rem;
  }
}
@media (min-width: 768px) {
  #co-hero {
    margin-top: 5rem;
  }
  #co-hero .container {
    padding: 3rem 2.5rem 4rem 2.5rem;
    row-gap: 2rem;
  }
  #co-hero .container > p:first-child {
    font-size: 2.25rem;
  }
}
@media (min-width: 1024px) {
  #co-hero .container {
    padding: 4rem 3rem 6rem 3rem;
  }
  #co-hero .container > p:first-child {
    font-size: 2.5rem;
  }
}
@media (min-width: 1280px) {
  #co-hero .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #co-hero .container {
    max-width: 1536px;
    padding: 4rem 5rem 6rem 5rem;
  }
}
