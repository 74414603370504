.plate-card-skeleton {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0.875rem 0.875rem;
  animation: pulse 2s infinite;
  overflow-x: hidden;
}
.plate-card-skeleton .canvas {
  border-radius: 0.8rem 0.8rem 0 0;
  height: 9rem;
  background-color: #e5e7eb;
}
.plate-card-skeleton .card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 2rem;
  row-gap: 0.5rem;
  height: 9rem;
}
.plate-card-skeleton .card-footer div {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.plate-card-skeleton .price {
  width: 45%;
  height: 1.125rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
}
.plate-card-skeleton .location {
  width: 70%;
  height: 0.875rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
}
.plate-card-skeleton .phone {
  width: 90%;
  height: 0.75rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
