#inventory-tabs {
  display: flex;
  column-gap: 2rem;
  border-bottom: 1px solid var(--primary-200);
}
#inventory-tabs .tab {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--primary-500);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  text-decoration: none;
  column-gap: 0.75rem;
  outline: none;
  border: none;
  border-bottom: 1.5px solid transparent;
  transition: all 0.3s ease-in-out;
}
#inventory-tabs .tab:hover {
  border-color: var(--primary-900);
  color: var(--primary-900);
}
@media (min-width: 580px) {
  #inventory-tabs {
    column-gap: 2rem;
  }
  #inventory-tabs .tab {
    font-size: 0.875rem;
  }
}
