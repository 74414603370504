@import url("./fonts.css");

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  opacity: 0;
  animation: fadeIn ease-in 1.25s forwards;
  -webkit-tap-highlight-color: transparent;
  color-scheme: light;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Color variables */
:root {
  --primary-50: #f6f6f7;
  --primary-100: #e9e9eb;
  --primary-200: #dcdce0;
  --primary-300: #a0a0a8;
  --primary-400: #7c7c85;
  --primary-500: #61616b;
  --primary-800: #35363a;
  --primary-900: #28282b;
  --primary-950: #28282b;
  --sold-red: #c5312d;
  --red-600: #dc3a3a;
  --green-600: #19b620;
  --blue-500: #2b8bc1;
  --bg-green: #19b62108;
  --radius-8: 0.5rem;
}

.error,
.fetch-error,
.no-cars,
.no-results {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  color: var(--primary-900);
  line-height: 1.25;
  word-wrap: break-word;
  width: 100%;
}

.error,
.fetch-error {
  font-size: 0.75rem;
  color: var(--red-600);
}

.fetch-error,
.no-cars,
.no-results {
  font-size: 0.875rem;
}

.no-results {
  font-size: 1rem;
}

/* Input styles */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Toast styles */
.Toastify {
  position: absolute;
}
.Toastify__toast-body > div:last-child {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  color: var(--primary-900);
  font-size: 0.875rem;
  line-height: 1.25;
}

::selection {
  background-color: var(--primary-100);
  color: var(--primary-900);
}
