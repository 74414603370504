#day {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  width: 100%;
}
#day > p {
  display: flex;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  width: 5.125rem;
}
#day select {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  background-color: #fff;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  outline: none;
  padding: 0.25rem;
}
#day .times {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
}
#day .times p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#day .times input {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  width: 100%;
}
#day .times input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0.6;
}
#day .times input:disabled ~ p {
  color: var(--primary-300);
}
#day .times input:disabled {
  background-color: var(--primary-50);
  color: var(--primary-300);
}
.times-solid:not(:last-child) {
  border-bottom: 1px solid var(--primary-100);
  margin: 0.5rem 0;
}

@media (min-width: 500px) {
  #day {
    flex-wrap: nowrap;
  }
  #day > p {
    align-items: center;
  }
  #day .times {
    width: initial;
  }
  .times-solid {
    display: none;
  }
  #day .times input {
    width: 6.25rem;
  }
}
