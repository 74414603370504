#side-tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#side-tabs .top-tabs {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#side-tabs .bottom-tabs {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#side-tabs .tab {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.75rem;
  color: var(--primary-500);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  text-decoration: none;
  background-color: transparent;
  column-gap: 0.75rem;
  outline: none;
  border: none;
  border-radius: var(--radius-8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#side-tabs .tab svg {
  flex-shrink: 0;
}
#side-tabs .tab:hover {
  background-color: var(--primary-50);
  cursor: pointer;
}
