#phone-numbers {
  display: flex;
  flex-direction: column;
}
#phone-numbers .success {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: 3rem;
  padding: 0rem 1rem;
  border: 1px solid var(--green-600);
  border-radius: var(--radius-8);
  background-color: var(--bg-green);
  margin-bottom: 1rem;
}
#phone-numbers .success p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#phone-numbers .success svg {
  flex-shrink: 0;
}
#phone-numbers form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
#phone-numbers .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#phone-numbers .phone-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

#phone-numbers form .phone-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  padding: 0 1rem;
  height: 3rem;
  column-gap: 1rem;
  overflow: hidden;
}
#phone-numbers form .phone-input-container:focus-within {
  border: 1px solid var(--primary-900);
}
#phone-numbers form .phone-input-container .country {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
}
#phone-numbers form .phone-input-container img {
  width: 1.5rem;
}
#phone-numbers form .phone-input-container p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}

#phone-numbers form .phone-input-container .input-group {
  position: relative;
  width: 100%;
}
#phone-numbers form .phone-input-container label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  left: 0;
  right: auto;
}
[dir="rtl"] #phone-numbers form .phone-input-container label {
  left: auto;
  right: 0;
}

#phone-numbers form .phone-input-container input {
  border: none;
  padding-top: 0.75rem;
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
[dir="rtl"] #phone-numbers form .phone-input-container input {
  text-align: right;
}

#phone-numbers form .phone-input-container input:focus {
  border-color: var(--primary-900);
}
#phone-numbers form .phone-input-container input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
  white-space: nowrap;
}
#phone-numbers
  form
  .phone-input-container
  input:not(:placeholder-shown)
  + label {
  top: 0.875rem;
  font-size: 0.75rem;
  white-space: nowrap;
}

#phone-numbers form .verified-phone {
  background-color: var(--primary-50);
  border: 1px solid var(--primary-200);
}
#phone-numbers form .verified-phone p {
  color: var(--primary-400);
}
#phone-numbers form .verified-phone img {
  opacity: 0.8;
}
#phone-numbers form .verified-phone label {
  color: var(--primary-400);
}
#phone-numbers form .verified-phone input {
  color: var(--primary-500);
}

#phone-numbers form .add-phone-btn {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: fit-content;
  height: 3rem;
  padding: 0rem 1.5rem;
  background-color: #fff;
  color: var(--primary-900);
  border: 1px solid var(--primary-900);
  border-radius: var(--radius-8);
  cursor: pointer;
  white-space: nowrap;
}
#phone-numbers form .add-phone-btn:hover {
  outline: 1px solid var(--primary-900);
  background-color: none;
}
#phone-numbers form #verify-btn {
  display: flex;
  align-items: center;
  align-self: flex-end;
  column-gap: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1.25rem;
  background-color: var(--primary-900);
  color: #fff;
  border: 1px solid var(--primary-900);
  border-radius: var(--radius-8);
  cursor: pointer;
  white-space: nowrap;
}
#phone-numbers form #verify-btn:hover {
  background-color: var(--primary-800);
}
#phone-numbers form #verify-btn:disabled {
  background-color: var(--primary-200);
  border-color: var(--primary-200);
  color: var(--primary-500);
  cursor: not-allowed;
}

#phone-numbers form .delete-phone-btn {
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  cursor: pointer;
}

@media (min-width: 480px) {
  #phone-numbers .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #phone-numbers .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
