#delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}
#delete-modal .close-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.delete-modal-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;
  max-width: 32rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: var(--radius-8);
}
.delete-modal-container header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
}
.delete-modal-container header .title {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--primary-900);
}
.delete-modal-container header p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
.delete-modal-container .delete-modal-btns {
  display: flex;
  justify-content: center;
  row-gap: 0.25rem;
  column-gap: 0.75rem;
  width: 100%;
  flex-wrap: wrap;
}

.delete-modal-container .delete-modal-btns .cancel-btn {
  padding: 0 2rem;
  height: 3rem;
  border-radius: var(--radius-8);
  background-color: #fff;
  color: var(--primary-900);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  transition: 0.2s ease-in-out;
  outline: none;
  border: 1px solid var(--primary-900);
}
.delete-modal-container .delete-modal-btns .cancel-btn:hover {
  background-color: var(--primary-50);
  cursor: pointer;
}
.delete-modal-container .delete-modal-btns .delete-btn {
  padding: 0 2rem;
  height: 3rem;
  border-radius: var(--radius-8);
  background-color: var(--red-600);
  color: #fff;
  border: 1px solid var(--red-600);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  transition: 0.2s ease-in-out;
  outline: none;
}
.delete-modal-container .delete-modal-btns .delete-btn:hover {
  cursor: pointer;
  background-color: #dc3a3ae4;
  border: 1px solid #dc3a3ad5;
}
