#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #28282b;
  margin-top: 4rem;
  padding: 2.5rem 0;
  row-gap: 2.5rem;
}
#footer .container {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}
#footer .container div .logo {
  width: 14rem;
}
#footer .container div .header {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
}
#footer .container div .links {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  line-height: 1.75;
}
#footer .container div .links .link {
  width: fit-content;
  color: #fff;
  text-decoration: none;
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}
#footer .container .links .link:hover {
  border-bottom: 1px solid #fff;
}
#footer .bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
#footer .bottom p {
  color: #fff;
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 0.75rem;
  line-height: 1.25;
}
#footer .social .social-links {
  display: flex;
  gap: 0.75rem;
}
#footer .social .social-links a svg {
  transition: transform 0.5s ease-in-out;
}
#footer .social .social-links a:hover svg {
  transform: scale(1.1);
}
#footer .container .solid {
  display: none;
  width: 100%;
  border-bottom: 1.5px solid #f0f0f02d;
  border-radius: 4px;
}
#footer .container .store-badges {
  display: none;
  gap: 0.5rem;
}
#footer .bottom .store-badges {
  display: flex;
  gap: 0.5rem;
}
@media (max-width: 800px) {
  #footer {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  }
  #footer .container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  #footer .container .solid {
    display: initial;
  }
  #footer .container div .links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #footer .container .social,
  #footer .container .f-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #footer .bottom {
    width: fit-content;
  }
  #footer .container .store-badges {
    display: flex;
  }
  #footer .bottom .store-badges {
    display: none;
  }
}
@media (min-width: 640px) {
  #footer .container {
    padding: 0 2rem;
  }
  #footer .bottom {
    padding: 0 2rem;
  }
}
@media (min-width: 768px) {
  #footer .container {
    padding: 0 2.5rem;
  }
  #footer .bottom {
    padding: 0 2.5rem;
  }
}
@media (min-width: 1024px) {
  #footer .container {
    padding: 0 3rem;
  }
  #footer .container div .logo {
    width: 16rem;
  }
  #footer .bottom {
    padding: 0 3rem;
  }
}
@media (min-width: 1280px) {
  #footer .container {
    max-width: 1280px;
  }
  #footer .bottom {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #footer .container {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #footer .bottom {
    max-width: 1536px;
    padding: 0 5rem;
  }
}
