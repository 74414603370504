#cd-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #28282b;
  margin-top: 4rem;
  padding-bottom: 5rem;
}
#cd-hero header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 1rem 0rem 1rem;
  margin-bottom: 1rem;
}
#cd-hero header h1:nth-child(1) {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.25;
  color: #fff;
}
#cd-hero header h2:nth-child(2) {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  color: #fff;
}

#cd-hero header .share-btn {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0.5rem;
}
#cd-hero .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  column-gap: 1rem;
}
#cd-hero .left-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
}
#cd-hero .car-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#cd-hero .car-info > p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.5rem;
  line-height: 1.25;
  color: #fff;
}
#cd-hero .carInfo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}
#cd-hero .carInfo-container table {
  border-spacing: 0px;
  width: 100%;
}
#cd-hero .carInfo-container table tr td {
  height: 3.5rem;
  border-bottom: 0.75px solid #fff;
}
#cd-hero .carInfo-container table tr td:nth-child(1) {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1rem;
  line-height: 1.25;
  width: 50%;
}
#cd-hero .carInfo-container table tr td:nth-child(2) {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  width: 50%;
}
#cd-hero .left-container .features {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  row-gap: 0.75rem;
}
#cd-hero .left-container .features > p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.5rem;
  line-height: 1.25;
}
#cd-hero .features .features-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

#cd-hero .features-container .feature {
  background-color: #ffffff0a;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-8);
}
#cd-hero .features-container p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
}
#cd-hero .description {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: #fff;
}
#cd-hero .description p:nth-child(1) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.5rem;
  line-height: 1.25;
}

#cd-hero .description p:nth-child(2) {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
}
#cd-hero .right-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
}
#cd-hero .seller-info {
  display: flex;
  flex-direction: column;
  color: #fff;
  border: 1px solid #fff;
  padding: 1.5rem;
  gap: 0.75rem;
  height: fit-content;
}
#cd-hero .seller-info > p:nth-child(1) {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1.25rem;
  line-height: 1.25;
}
#cd-hero .seller-info > p:nth-child(2) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.75rem;
  line-height: 1.25;
}
#cd-hero .seller-info #sold-badge {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.125rem;
  line-height: 1.25;
  background-color: var(--sold-red);
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
  width: fit-content;
}
#cd-hero .seller-info .solid {
  border-top: 1px solid #f0f0f0cb;
  border-radius: 4px;
  margin: 0.5rem 0;
}
#cd-hero .seller-info .row-2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
#cd-hero .seller-info .row-2 time {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 1.125rem;
  line-height: 1.25;
}
#cd-hero .seller-info .row-2 p {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 1.125rem;
  line-height: 1.25;
}

#cd-hero .showroom-info-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  gap: 0.75rem;
}
#cd-hero .showroom-info-container > p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1.25rem;
  line-height: 1.25;
  margin-bottom: 0.25rem;
}
#cd-hero .showroom-info {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: fit-content;
}
#cd-hero .showroom-info p {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 1.25rem;
  line-height: 1.25;
  font-size: 1.125rem;
  color: #fff;
}
#cd-hero .img {
  width: 4rem;
  height: 4rem;
  border-radius: var(--radius-8);
  overflow: hidden;
  border: 1px solid #fff;
  flex-shrink: 0;
}
#cd-hero .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#cd-hero .showroom-info-container .link-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  background-color: #fff;
  border: 1px solid #fff;
  color: var(--primary-900);
  padding: 1rem 1.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  line-height: 1.25;
  font-size: 0.875rem;
  text-decoration: none;
}
#cd-hero .showroom-info-container .link-phone svg,
#cd-hero .showroom-info-container .link-whatsapp svg {
  flex-shrink: 0;
}

#cd-hero .showroom-info-container .link-phone:hover {
  background-color: rgba(255, 255, 255, 0.892);
}
#cd-hero .showroom-info-container .link-whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 1rem 1.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  line-height: 1.25;
  font-size: 0.875rem;
  text-decoration: none;
}
#cd-hero .showroom-info-container .link-whatsapp:hover {
  box-shadow: 0px 0px 0px 0.75px #fff;
}
#cd-hero .ad-info {
  color: #fff;
  border: 1px solid #fff;
}
#cd-hero .ad-info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#cd-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

#cd-feature .sale {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  row-gap: 1.5rem;
}
#cd-feature .sale .heading {
  align-self: start;
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
#cd-feature .sale .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}

@media (min-width: 640px) {
  #cd-hero header {
    padding: 2rem 2rem 0rem 2rem;
  }
  #cd-hero .container {
    padding: 0 2rem;
  }
  #cd-hero .carInfo-container {
    flex-direction: row;
  }
  #cd-hero .carInfo-container table {
    width: 45%;
  }
  #cd-feature .sale {
    padding: 0 2rem;
  }
  #cd-feature .sale .heading {
    font-size: 1.875rem;
  }
  #cd-feature .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  #cd-hero {
    margin-top: 5rem;
  }
  #cd-hero header {
    padding: 2rem 2.5rem 0rem 2.5rem;
  }
  #cd-hero header h1:nth-child(1) {
    font-size: 2.125rem;
  }
  #cd-hero header h2:nth-child(2) {
    font-size: 1.5rem;
  }
  #cd-hero .container {
    padding: 0 2.5rem;
  }
  #cd-feature .sale {
    padding: 0 2.5rem;
    row-gap: 2rem;
  }
  #cd-feature .sale .heading {
    font-size: 2.25rem;
  }
  #cd-feature .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 900px) {
  #cd-feature .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  #cd-hero header {
    padding: 2rem 3rem 0rem 3rem;
  }
  #cd-hero header h1:nth-child(1) {
    font-size: 2.5rem;
  }
  #cd-hero header h2:nth-child(2) {
    font-size: 1.75rem;
  }
  #cd-hero .container {
    padding: 0 3rem;
  }
  #cd-feature .sale {
    padding: 0 3rem;
  }
  #cd-feature .sale .heading {
    font-size: 2.5rem;
  }
  #cd-feature .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1080px) {
  #cd-hero .container {
    flex-direction: row;
  }
  #cd-hero .left-container {
    width: 70%;
  }
  #cd-hero .right-container {
    width: 30%;
    margin-top: 0rem;
  }
}
@media (min-width: 1280px) {
  #cd-hero header {
    max-width: 1280px;
  }
  #cd-hero .container {
    max-width: 1280px;
  }
  #cd-hero .showroom-info-container .link-phone,
  #cd-hero .showroom-info-container .link-whatsapp {
    font-size: 1rem;
  }
  #cd-feature .sale {
    max-width: 1280px;
  }
  #cd-feature .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  #cd-hero header {
    max-width: 1536px;
    padding: 2rem 5rem 0rem 5rem;
  }
  #cd-hero .container {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #cd-feature .sale {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #cd-feature .sale .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
