#add-car {
  display: flex;
  flex-direction: column;
}
#add-car .btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}
#add-car .back {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: #fff;
  color: var(--primary-900);
  border: 1px solid var(--primary-900);
  border-radius: var(--radius-8);
  cursor: pointer;
}
#add-car .back:hover {
  outline: 1px solid var(--primary-900);
}
#add-car .next {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#add-car .next:hover {
  background-color: var(--primary-800);
}
