#verify-otp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;
}
#verify-otp header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
}
#verify-otp header .title {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#verify-otp header p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#verify-otp .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  width: 100%;
}
#verify-otp .input-group {
  position: relative;
  width: 100%;
}
#verify-otp .input-group label {
  position: absolute;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-400);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  top: 0.875rem;
}

[dir="rtl"] #verify-otp .input-group label {
  right: 1rem;
}
#verify-otp .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#verify-otp .input-group input {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#verify-otp .input-group input:focus {
  border-color: var(--primary-900);
}
#verify-otp .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

#verify-otp button {
  padding: 0 3rem;
  height: 3rem;
  border-radius: var(--radius-8);
  background-color: var(--primary-900);
  color: var(--primary-50);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  transition: 0.2s ease-in-out;
  outline: none;
  border: 1px solid var(--primary-900);
}
#verify-otp button:hover {
  background-color: var(--primary-800);
  cursor: pointer;
}
#verify-otp button:disabled {
  opacity: 0.5;
}
