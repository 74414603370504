#mileage-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
#mileage-details header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#mileage-details .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#mileage-details .row .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}
#mileage-details .mileage {
  display: flex;
  column-gap: 1px;
  background-color: var(--primary-300);
  border-radius: var(--radius-8);
}
#mileage-details .mileage .mileage-unit {
  min-width: 9rem;
}
#mileage-details .mileage .mileage-unit .dd-header {
  /* border: none; */
  border-inline-end: none;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  /* border-start */
}
#mileage-details .mileage .input-group {
  position: relative;
  width: 100%;
}
#mileage-details .mileage .input-group label {
  position: absolute;
  top: 0.875rem;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
[dir="rtl"] #mileage-details .mileage .input-group label {
  right: 1.07rem;
}
#mileage-details .mileage .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#mileage-details .mileage .input-group input {
  border: 1px solid var(--primary-300);
  border-inline-start: none;
  border-start-end-radius: var(--radius-8);
  border-end-end-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#mileage-details .mileage .input-group input:focus {
  border-color: var(--primary-900);
}
#mileage-details .mileage .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

@media (min-width: 500px) {
  #mileage-details .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #mileage-details .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
