#showroom-analytics-table {
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
  border-radius: var(--radius-8);
  overflow-x: auto;
}
#showroom-analytics-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}
#showroom-analytics-table table thead {
  border-bottom: 1px solid var(--primary-200);
}
#showroom-analytics-table table thead th {
  padding: 1rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  text-align: start;
  font-weight: 500;
  white-space: nowrap;
}
#showroom-analytics-table table tbody {
  overflow: scroll;
}
#showroom-analytics-table table tbody tr {
  border-bottom: 1px solid var(--primary-50);
}
#showroom-analytics-table table tbody tr td {
  padding: 1rem;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#showroom-analytics-table .td-1 {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  width: fit-content;
}
#showroom-analytics-table .td-1 img {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 4.5rem;
  height: 3.5rem;
  object-fit: cover;
}
#showroom-analytics-table td > p:nth-child(2) {
  font-size: 0.75rem;
}
#showroom-analytics-table .name {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  white-space: nowrap;
}
#showroom-analytics-table .type {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#showroom-analytics-table .view {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}
#showroom-analytics-table .cars {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
}
