#navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  height: 4rem;
  background: #fff;
  z-index: 52;
}
#navbar nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background: #fff;
  padding: 0 1rem;
  z-index: 1000;
}
#navbar nav .left {
  display: flex;
  gap: 2rem;
  height: fit-content;
}
#navbar nav .logo img {
  width: 7rem;
  height: 100%;
}
#navbar nav .logo {
  align-self: center;
}
#navbar nav .navlinks {
  display: none;
  gap: 2rem;
  align-self: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  line-height: 1.25;
}
#navbar nav .navlinks .nav-link {
  text-decoration: none;
  outline: none;
  position: relative;
  color: var(--primary-900);
  font-size: 1rem;
}
#navbar nav .navlinks .nav-link::after {
  content: "";
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  width: 0%;
  height: 0.125rem;
  background-color: var(--primary-900);
  transition: all 0.3s ease-in-out;
}
[dir="rtl"] #navbar nav .navlinks .nav-link::after {
  right: 0;
  left: initial;
}
#navbar nav .navlinks .nav-link:hover::after {
  width: 100%;
}

/* disabled nav link style */
#navbar nav .navlinks .nav-link.disabled {
  color: var(--primary-300);
  cursor: not-allowed;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  position: relative;
}
#navbar nav .navlinks .nav-link.disabled::after {
  display: none;
}
/* disabled link soon badge style */
#navbar nav .navlinks .nav-link .soon-badge {
  position: absolute;
  top: -1.15rem;
  right: 0;
  font-size: 0.75rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  padding: 0.125rem 0.375rem;
  border-radius: var(--radius-8);
  background-color: var(--blue-500);
  color: white;
  line-height: 1.25;
}
[dir="rtl"] #navbar nav .navlinks .nav-link .soon-badge {
  right: initial;
  left: 0;
}

#navbar nav .right {
  width: fit-content;
  display: flex;
  gap: 0.5rem;
}
#navbar nav .language {
  display: none;
  z-index: 999;
}
#navbar nav .right .profile {
  z-index: 999;
  display: none;
}
#navbar nav .right .register {
  display: flex;
  align-items: center;
  display: none;
}

#navbar .right .nav-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  height: 2.575rem;
  background-color: var(--primary-900);
  color: #fff;
  border: 1px solid var(--primary-900);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  padding: 0rem 1.5rem;
}
#navbar .right .nav-link:hover {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
}
#navbar .right .nav-link:active {
  background-color: var(--primary-950);
  border-color: var(--primary-950);
}

#navbar .menu {
  display: flex;
  border: none;
  cursor: pointer;
  background: none;
  align-self: center;
}

.nav-overlay {
  background-color: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.menu-links {
  position: fixed;
  width: 80%;
  max-width: 20rem;
  top: 4rem;
  bottom: 0;
  z-index: 51;
  overflow-y: scroll;
  background-color: #fff;
}
.menu-links-ltr {
  right: 0;
  transform: translateX(130%);
}
.menu-links-rtl {
  left: 0;
  transform: translateX(-130%);
}
.menu-links > div:nth-child(1) {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  gap: 1rem;
}
.menu-links header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
}
.menu-links header .register {
  background-color: var(--primary-900);
  color: #fff;
  text-decoration: none;
  height: 3rem;
  border-radius: var(--radius-8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
}
.menu-links header .sign-in {
  background-color: #fff;
  border: 1px solid var(--primary-900);
  color: var(--primary-900);
  text-decoration: none;
  height: 3rem;
  border-radius: var(--radius-8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
}

.menu-links .nav-link {
  text-decoration: none;
  position: relative;
  color: var(--primary-900);
  width: fit-content;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
}
.menu-links .nav-link::after {
  content: "";
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  width: 0%;
  height: 0.125rem;
  background-color: var(--primary-900);
  transition: all 0.3s ease-in-out;
}
[dir="rtl"] .menu-links .nav-link::after {
  right: 0;
  left: initial;
}
.menu-links .nav-link:hover::after {
  width: 100%;
}
/* disabled nav link style */
.menu-links .nav-link.disabled {
  /* margin-top: 0.5rem; */
  color: var(--primary-300);
  cursor: not-allowed;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.menu-links .nav-link.disabled::after {
  display: none;
}
/* disabled link soon badge style */
.menu-links .nav-link .soon-badge {
  position: absolute;
  top: -0.6rem;
  right: -2.75rem;
  font-size: 0.75rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  padding: 0.125rem 0.375rem;
  border-radius: var(--radius-8);
  background-color: var(--blue-500);
  color: white;
  line-height: 1.25;
}
[dir="rtl"] .menu-links .nav-link .soon-badge {
  right: initial;
  left: -2.15rem;
  top: -1.1rem;
}
.menu-links .solid {
  border-bottom: 1px solid var(--primary-200);
  border-radius: 0.25rem;
  margin: 0.5rem 0;
}

@media (min-width: 640px) {
  #navbar nav {
    padding: 0 2rem;
  }

  .menu-links > div {
    padding: 1.5rem 2rem;
  }
}
@media (min-width: 768px) {
  #navbar {
    height: 5rem;
  }
  #navbar nav {
    height: 5rem;
    padding: 0 2.5rem;
  }
  #navbar nav .logo img {
    width: 8rem;
  }
  .menu-links {
    top: 5rem;
  }
  .menu-links > div {
    padding: 1.5rem 2.5rem;
  }
}
@media (min-width: 1024px) {
  #navbar nav {
    padding: 0 3rem;
  }
}
@media (min-width: 1180px) {
  #navbar nav {
    justify-content: space-between;
    padding: 0 3rem;
  }
  #navbar nav .navlinks {
    display: flex;
  }
  #navbar nav .menu {
    display: none;
  }
  #navbar nav .language,
  #navbar nav .right .profile {
    display: initial;
    z-index: 999;
  }
  #navbar nav .right .register {
    display: flex;
  }
  .menu-links {
    display: none;
  }
  .menu-links > div {
    display: none;
  }
  .nav-overlay {
    display: none;
  }
}
@media (min-width: 1280px) {
  #navbar nav {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #navbar nav {
    max-width: 1536px;
    padding: 0 5rem;
  }
}
