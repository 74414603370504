.plates .card {
  display: flex;
  flex-direction: column;
  background: var(--primary-900);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.875rem 0.875rem;
}
.card canvas {
  border-radius: 0.8rem 0.8rem 0 0;
}
.plates .card .card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 2rem;
  row-gap: 0.5rem;
  height: 9rem;
}

.plates .card p {
  color: #fff;
}
.plates .price {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.25rem;
  line-height: 1.25;
}

.plates .card .sold {
  background-color: var(--sold-red);
  color: #fff;
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.125rem;
  line-height: 1.25;
  padding: 0.25rem 1.25rem;
  border-radius: 0.25rem;
}
.plates .location {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 1rem;
  line-height: 1.25;
}
.plates .phone {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 0.875rem;
  line-height: 1.25;
}
.plates .card .card-footer div {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
