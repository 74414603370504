#my-account {
  display: flex;
  flex-direction: column;
}
#my-account .success {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: 3rem;
  padding: 0rem 1rem;
  border: 1px solid var(--green-600);
  border-radius: var(--radius-8);
  background-color: var(--bg-green);
  margin-bottom: 1rem;
}
#my-account .success p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#my-account .success svg {
  flex-shrink: 0;
}
#my-account form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
#my-account .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#my-account .row .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#my-account form .input-group {
  position: relative;
  width: 100%;
}

#my-account form .input-group label {
  position: absolute;
  top: 0.875rem;
  left: 1.07rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
[dir="rtl"] #my-account form .input-group label {
  right: 1.07rem;
}
#my-account form .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}

#my-account form .input-group input,
#my-account form .input-group textarea {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#my-account form .disabled-input input {
  background-color: var(--primary-50);
  border-color: var(--primary-200);
  color: var(--primary-400);
}
#my-account form .disabled-input label {
  color: var(--primary-300);
}

#my-account form .input-group textarea {
  height: 8rem;
  padding-top: 1.25rem;
  resize: none;
  overflow: auto;
}
#my-account form .input-group textarea ~ label {
  top: 0.875rem;
  font-size: 0.75rem;
  background-color: #fff;
  width: calc(100% - 2.125rem);
  transform: translateY(-50%);
}
#my-account form .input-group textarea:placeholder-shown ~ label {
  top: 1.25rem;
  font-size: 0.875rem;
}
#my-account form .input-group input:focus,
#my-account form .input-group textarea:focus {
  border-color: var(--primary-900);
}
#my-account form .input-group input:focus + label,
#my-account form .input-group textarea:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}
#my-account form .input-group #verify-email {
  display: flex;
  align-items: center;
  float: inline-end;
  column-gap: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1.25rem;
  background-color: var(--primary-900);
  color: #fff;
  border: 1px solid var(--primary-900);
  border-radius: var(--radius-8);
  cursor: pointer;
  white-space: nowrap;
  margin-top: 0.25rem;
}

#my-account form .input-group #verify-email:hover {
  background-color: var(--primary-800);
}

#my-account form .address {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#my-account form .address p:nth-child(1) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}

#my-account form .address .map {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

#my-account .opening-times {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#my-account .opening-times > p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#my-account .opening-times .days {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  padding: 1rem;
}

#my-account .img-row .images-input {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  border: 1px dashed var(--primary-300);
  border-radius: var(--radius-8);
}
#my-account .img-row .images-input input {
  display: none;
}
#my-account .img-row .images-input label {
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  gap: 0.25rem;
  width: 100%;
  padding: 2rem;
}
#my-account .images-preview {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem;
  margin-top: 0.5rem;
}
#my-account .images-preview > div {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#my-account .images-preview > div button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  background-color: #fff;
  outline: none;
  color: var(--primary-900);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: nowrap;
}
#my-account .images-preview > div button:hover {
  background-color: var(--primary-50);
}
#my-account .images-preview > div button svg {
  flex-shrink: 0;
}
#my-account .images-preview .image {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  position: relative;
  border-bottom: 2px solid var(--primary-900);
  border-top: 2px solid var(--primary-900);
}
#my-account .images-preview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#my-account .images-preview .image button {
  outline: none;
  background-color: var(--primary-50);
  border-radius: 0.375rem;
  border: none;
  padding: 0.3rem;
  border: 0.25px solid var(--primary-300);
  position: absolute;
  display: flex;
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
  z-index: 2;
}

#my-account .images-preview .change-input {
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  overflow: hidden;
  z-index: 1;
}
#my-account .images-preview .change-input input {
  position: absolute;
  cursor: pointer;
  display: none !important;
  inset: 0;
  left: 0;
  cursor: pointer;
}
#my-account .images-preview .change-input label {
  cursor: pointer;
  position: absolute;
  inset: 0;
  left: 0;
  cursor: pointer;
}

#my-account form .action-btns {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
}
#my-account form .action-btns button[type="button"] {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3.25rem;
  padding: 0 2rem;
  background-color: #fff;
  color: var(--red-600);
  border: 1px solid var(--red-600);
  border-radius: var(--radius-8);
  cursor: pointer;
  align-self: flex-end;
}
#my-account form .action-btns button[type="button"]:hover {
  background-color: rgba(219, 15, 15, 0.05);
  color: var(--red-600);
}
#my-account form .action-btns button[type="submit"] {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3.25rem;
  padding: 0 2rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
  align-self: flex-end;
}
#my-account form .action-btns button[type="submit"]:hover {
  background-color: var(--primary-800);
}

#my-account form .last-row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 1rem;
}

@media (min-width: 500px) {
  #my-account .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #my-account .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #my-account form .last-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
