.card-skeleton {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.875rem 0.875rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: pulse 2s infinite;
}
.card-skeleton .card-header-skeleton {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 0.375rem;
}
.card-skeleton .card-img-container-skeleton {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 0.875rem 0.875rem 0 0;
  background-color: #e5e7eb;
}
.card-skeleton .card-logo-skeleton {
  background-color: #e5e7eb;
  width: 5rem;
  height: 5rem;
  position: absolute;
  bottom: -1rem;
  border-radius: 0.5rem;
  margin-inline-start: 1.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border: 2px solid #fff;
}
.card-skeleton .card-logo-skeleton.hidden {
  display: none;
}
.card-skeleton .card-footer-skeleton {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.card-skeleton .card-footer-skeleton > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.card-skeleton .card-footer-skeleton .solid {
  height: 0.0625rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
}
.card-skeleton .make-skeleton {
  height: 1.25rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
  width: 75%;
}
.card-skeleton .model-skeleton {
  height: 1rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
}
.card-skeleton .model-skeleton.hidden {
  display: none;
}
.card-info-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.card-info-skeleton div {
  display: flex;
  column-gap: 0.5rem;
  width: 45%;
  height: 0.875rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@media (min-width: 450px) {
  .card-skeleton .card-img-container-skeleton {
    padding-bottom: 65%;
  }
}
@media (min-width: 640px) {
  .card-skeleton .card-img-container-skeleton {
    padding-bottom: 75%;
  }
}
