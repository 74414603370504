#support {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  background-color: #fff;
  border-radius: var(--radius-8);
  row-gap: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
}
#support header {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#support header .support-heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#support header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#support .container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

#support .container .contact-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#support .container .contact-info div {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
#support .container .contact-info div a {
  text-decoration: none;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#support .container .contact-info div a:hover {
  text-decoration: underline;
}
#support .container .desc {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
