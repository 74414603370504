#not-found {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0;
}
#not-found .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
}
#not-found .container h1:nth-child(1) {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  line-height: 1.25;
  font-size: 8.5rem;
  color: var(--primary-900);
  text-align: center;
}
#not-found .container h1:nth-child(2) {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 2rem;
  line-height: 1.25;
  color: var(--primary-900);
  text-align: center;
}
#not-found .container .link {
  margin-top: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: #fff;
  background-color: var(--primary-900);
  transition: all 0.2s ease-out;
}
#not-found .container .link:hover {
  background-color: var(--primary-800);
}
