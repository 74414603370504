#login {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
#login .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2.5rem 1rem;
  row-gap: 0.75rem;
}
#login .container .logo img {
  width: 15rem;
}
#login .container > p:nth-child(2) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#login form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.75rem;
}
#login .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
#login .row .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

#login .row .password {
  display: flex;
  position: relative;
  width: 100%;
}
#login .row .password button {
  width: fit-content;
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
[dir="rtl"] #login .row .password button {
  left: 1rem;
  right: auto;
}

#login .input-group {
  position: relative;
  width: 100%;
}
#login .input-group label {
  position: absolute;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-400);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  top: 0.875rem;
}

[dir="rtl"] #login .input-group label {
  right: 1rem;
}
#login .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#login .input-group input {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
[dir="rtl"] #login .phone-input-container input {
  text-align: right;
}

#login .input-group input:focus {
  border-color: var(--primary-900);
}
#login .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}
#login .phone-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  padding: 0 1rem;
  column-gap: 1rem;
}
#login .phone-input-container:focus-within {
  border: 1px solid var(--primary-900);
}
#login .phone-input-container .country {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
}
#login .phone-input-container img {
  width: 1.5rem;
}
#login .phone-input-container p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}

#login .phone-input-container .phone-input-group {
  position: relative;
  width: 100%;
}
#login .phone-input-container label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-400);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  left: 0;
  right: auto;
}
[dir="rtl"] #login .phone-input-container label {
  left: auto;
  right: 0;
}

#login .phone-input-container input {
  border: none;
  padding-top: 0.75rem;
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#login .phone-input-container input:focus {
  border-color: var(--primary-900);
}
#login .phone-input-container input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}
#login .phone-input-container input:not(:placeholder-shown) + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

#login form .login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  width: 100%;
  height: 3.25rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#login form .login-btn:hover {
  background-color: var(--primary-800);
}
#login form .link {
  text-decoration: none;
  align-self: flex-end;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  cursor: pointer;
}
#login form .link:hover {
  text-decoration: underline;
}

#login .register {
  margin-top: 0.375rem;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}

#login .register .link {
  width: 100%;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  cursor: pointer;
  text-decoration: none;
}
#login .register .link:hover {
  text-decoration: underline;
}

@media (min-width: 480px) {
  #login form {
    width: 25rem;
  }
}
@media (min-width: 640px) {
  #login .container {
    padding: 2.5rem 2rem;
  }
}
@media (min-width: 768px) {
  #login .container {
    padding: 2.5rem 2.5rem;
  }
}
@media (min-width: 1024px) {
  #login .container {
    padding: 2.5rem 3rem;
  }
}
@media (min-width: 1280px) {
  #login .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #login .container {
    padding: 2.5rem 5rem;
    max-width: 1536px;
  }
}
