#mob-profile .dd-container {
  cursor: pointer;
  max-width: fit-content;
  display: flex;
}
#mob-profile .dd-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  column-gap: 1rem;
  background-color: var(--primary-50);
  padding: 0.75rem 0.75rem;
  border-radius: var(--radius-8);
}
#mob-profile .dd-header img {
  border: 1px solid #28282b32;
  border-radius: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
}
#mob-profile .dd-header p {
  font-family: "Montserrat-MEdium", "NotoSansArabic-MEdium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#mob-profile .dd-header svg {
  flex-shrink: 0;
}
#mob-profile .ddl-container {
  position: absolute;
  min-width: 90%;
  margin-top: 5.375rem;
  left: 1rem;
  right: 1rem;
  border-radius: var(--radius-8);
  background: #fff;
  border: 1px solid #28282b32;
  z-index: 2;
}
#mob-profile .dd-list {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#mob-profile .dd-list li {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  width: 100%;
  list-style: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 0.5rem;
}
#mob-profile .dd-list li:hover {
  background-color: #f6f6f7;
}
