#profile .dd-container {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#profile .dd-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #28282b32;
  border-radius: 0.5rem;
  height: 2.575rem;
  width: 2.75rem;
  overflow: hidden;
}
#profile .dd-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#profile .ddl-container {
  position: absolute;
  min-width: 15rem;
  margin-top: 2.75rem;
  border-radius: var(--radius-8);
  background: #fff;
  border: 1px solid #28282b32;
}
#profile .dd-list {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#profile .dd-list li {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  width: 100%;
  list-style: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 0.5rem;
}
#profile .dd-list li:hover {
  background-color: #f6f6f7;
}
