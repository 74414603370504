.rental .card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.875rem 0.875rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
}
.rental .card:hover .card-img {
  transform: scale(1.03);
  filter: brightness(80%);
  border-radius: 0.875rem 0.875rem 0 0;
}
.rental .card .card-header {
  position: relative;
  display: flex;
  width: 100%;
}
.rental .card .card-header .price {
  position: absolute;
  background-color: #28282bbd;
  background-color: var(--blue-500);
  color: #fff;
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1rem;
  line-height: 1.25;
  padding: 0.25rem 0.5rem;
  border-start-end-radius: 0.25rem;
  border-end-end-radius: 0.25rem;
  z-index: 1;
  top: 0.75rem;
}
.rental .card .card-header .price span {
  color: #fff;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
}
.rental .card .card-header .not-available {
  position: absolute;
  top: 0.75rem;
  background-color: var(--sold-red);
  color: #fff;
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.125rem;
  line-height: 1.25;
  padding: 0.25rem 1.25rem;
  z-index: 1;
  border-start-end-radius: 0.25rem;
  border-end-end-radius: 0.25rem;
}

.rental .card-header .card-img-container {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 0.875rem 0.875rem 0 0;
}
.rental .card-header .card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.875rem 0.875rem 0 0;
  transition: all 0.3s ease-in-out;
}
.rental .card .card-footer {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.rental .card .card-footer > div:nth-child(1) {
  display: flex;
  flex-direction: column;
}
.rental .card .make {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
.rental .card .model {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #28282b;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rental .card .card-footer .solid {
  border-bottom: 0.0625rem solid #e4dfdfcb;
  border-radius: 0.25rem;
}
.rental .card-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.rental .card-info div {
  display: flex;
  gap: 0.5rem;
}
.rental .card-info div svg {
  flex-shrink: 0;
}
.rental .card-info div p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: #28282b;
}

@media (min-width: 450px) {
  .rental .card-header .card-img-container {
    padding-bottom: 65%;
  }
}
@media (min-width: 640px) {
  .rental .card-header .card-img-container {
    padding-bottom: 75%;
  }
}
