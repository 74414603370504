#edit-rent-car {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: var(--radius-8);
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
}
#edit-rent-car .edit-heading {
  margin-bottom: 1rem;
}
#edit-rent-car .edit-heading p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#edit-rent-car form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
}

#edit-rent-car .btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  column-gap: 1rem;
}
#edit-rent-car .cancel {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: #fff;
  color: var(--primary-900);
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  cursor: pointer;
}
#edit-rent-car .cancel:hover {
  outline: 1px solid var(--primary-900);
}
#edit-rent-car .update {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#edit-rent-car .update:hover {
  background-color: var(--primary-800);
}
@media (min-width: 500px) {
  #edit-rent-car .success-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #edit-rent-car .success-row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
