#stat-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 0.5rem;
  background-color: #fff;
  border-radius: var(--radius-8);
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
}
#stat-card .title {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#stat-card .value {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#stat-card .percentage p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 0.875rem;
  line-height: 1.25;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
#stat-card .percentage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#stat-card .percentage .report-link {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--blue-500);
  text-decoration: none;
}
#stat-card .percentage .report-link:hover {
  text-decoration: underline;
}
