.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(50, 50);
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: #000;
  padding: 0.75rem 0.75rem;
  display: flex;
  flex-direction: column;
}
.full-screen-btn {
  position: relative;
  cursor: pointer;
  z-index: 1001;
  top: 0;
}
.full-screen-btn svg {
  position: absolute;
  top: -3.25rem;
  right: 0.75rem;
  cursor: pointer;
}
.swiper-carousel .prev-btn {
  display: flex;
  padding: 0.375rem;
  border-radius: 0.25rem;
  background: #28282b;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-top: 0.55rem;
  left: 1.65rem;
  z-index: 10;
  box-shadow: 0px 0px 30px -3px #000;
}
.full-screen .prev-btn-hidden {
  display: none;
}
.swiper-carousel .car-images-slider {
  margin: 0;
  width: 100%;
  height: 20rem;
  margin-bottom: 0.5rem;
}
.full-screen .car-images-slider {
  height: 100%;
  margin-bottom: 0.5rem;
  border: 0.75px solid #fff;
}
.swiper-carousel .car-images-slider .swiper-slide {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
.swiper-carousel .car-images-slider .swiper-slide-active {
  margin-inline-end: 10px;
}
.swiper-carousel .car-images-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.full-screen .car-images-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper-carousel .car-images-slider-thumbs {
  height: 4rem;
}
.full-screen .car-images-slider-thumbs {
  height: 3.5rem;
  width: 100%;
}
.swiper-carousel .car-images-slider-thumbs .swiper-slide {
  border: 0.75px solid var(--primary-900);
  opacity: 0.7;
}
.full-screen .car-images-slider-thumbs .swiper-slide {
  border: 0.75px solid transparent;
  opacity: 0.7;
}
.full-screen .car-images-slider-thumbs .swiper-wrapper {
  column-gap: 0.5rem;
}
.swiper-carousel .car-images-slider-thumbs .swiper-wrapper {
  column-gap: 0.5rem;
}

.swiper-carousel .swiper-button-next,
.swiper-carousel .swiper-button-prev {
  color: var(--primary-900);
  font-weight: 700;
  border-radius: 0.125rem;
  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.swiper-carousel .swiper-button-next::after,
.swiper-carousel .swiper-button-prev::after {
  font-size: 1rem;
  font-weight: 700;
}

.swiper-carousel .car-images-slider-thumbs .swiper-slide-thumb-active {
  border: 0.75px solid #fff;
  opacity: 1;
}
.swiper-carousel .car-images-slider-thumbs .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.full-screen .car-images-slider-thumbs .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 640px) {
  .swiper-carousel .prev-btn {
    display: none;
  }
  .swiper-carousel .car-images-slider {
    height: 30.86rem;
  }
  .full-screen .car-images-slider {
    height: 48rem;
  }
  .swiper-carousel .car-images-slider-thumbs {
    height: 6rem;
  }
  .full-screen .car-images-slider-thumbs {
    height: 5rem;
  }
}
@media (min-width: 768px) {
  .swiper-carousel .car-images-slider-thumbs {
    height: 6rem;
  }
  .full-screen .car-images-slider-thumbs {
    height: 6rem;
  }
}
@media (min-width: 1024px) {
  .full-screen .car-images-slider {
    height: 48rem;
  }
  .full-screen .car-images-slider-thumbs {
    height: 7rem;
  }
  .swiper-carousel .car-images-slider {
    margin-bottom: 0.88rem;
  }
}

@media (min-width: 1280px) {
  .swiper-carousel .car-images-slider {
    height: 31.025rem;
  }
  .full-screen .car-images-slider {
    height: 48rem;
  }
}
