#hero {
  margin-top: 4rem;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: flex;
  justify-content: center;
  height: 75vh;
  position: relative;
  overflow: hidden;
}
#hero .hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1; /* ensures the image stays in the background */
}
#hero .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  gap: 2rem;
  z-index: 2;
}
#hero .header .headline {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  color: #fff;
  line-height: 1.25;
}
#hero .header .headline h1 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 2rem;
  font-weight: 500;
  width: fit-content;
  align-self: center;
  text-align: center;
}
#hero .header .headline h2 {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  font-weight: 400;
  width: fit-content;
  align-self: center;
  text-align: center;
}
#hero .header button {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  align-self: center;
  font-size: 0.875rem;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0rem 1.5rem;
  height: 3rem;
  line-height: 1.25;
}
#hero .header button:hover {
  background-color: #fff;
  color: #28282b;
}
#search {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
}
#search > div {
  width: 100%;
  padding: 0 1rem;
}
#search form {
  display: flex;
  flex-direction: column;
  border: 2px solid #28282b25;
  background: #f6f6f7;
  border-radius: 0.5rem;
  padding: 2rem 1rem;
  gap: 1.5rem;
}
#search .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
  width: fit-content;
  align-self: center;
}
#search .filters {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 1rem;
  width: 100%;
}
#search form > div:nth-child(3) {
  display: flex;
  justify-content: flex-end;
}
#search .advanced-search {
  height: fit-content;
  text-decoration: none;
  color: var(--primary-500);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  font-size: 0.875rem;
  line-height: 1.25;
}
#search .advanced-search:hover {
  border-bottom: 2px solid var(--primary-500);
}

#search .search-btn {
  display: none;
  padding: 0rem 3.75rem;
  height: 3.25rem;
  color: #fff;
  background-color: var(--primary-900);
  border: none;
  border-radius: var(--radius-8);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#search .search-btn:hover {
  background-color: var(--primary-800);
}
#search .mob-search-btn {
  align-self: center;
  padding: 0rem 3.5rem;
  height: 3rem;
  color: #fff;
  background-color: var(--primary-900);
  border: none;
  border-radius: var(--radius-8);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#search .mob-search-btn:hover {
  background-color: var(--primary-800);
}

#feature {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
#feature .sale {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  row-gap: 1.5rem;
}
#feature .sale > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#feature .sale > div:nth-child(1) .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
#feature .sale .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}

#feature .rental {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  row-gap: 1.5rem;
}
#feature .rental > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#feature .rental > div:nth-child(1) .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
#feature .rental .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}

#feature .showroom {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  row-gap: 1.5rem;
}
#feature .showroom > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#feature .showroom > div:nth-child(1) .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
#feature .showroom .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}

#feature .sale .link,
#feature .rental .link,
#feature .showroom .link {
  text-decoration: none;
  display: none;
  align-items: center;
  padding: 0rem 2.5rem;
  height: 3rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: #28282b;
  border: 1px solid #28282b;
  border-radius: 0.5rem;
  cursor: pointer;
  column-gap: 0.5rem;
}
#feature .sale .link span,
#feature .rental .link span,
#feature .showroom .link span {
  display: flex;
  position: relative;
  left: 0;
  transition: left 0.3s ease-in-out;
}
#feature .sale .link:hover span,
#feature .rental .link:hover span,
#feature .showroom .link:hover span {
  left: 0.25rem;
}

[dir="rtl"] #feature .sale .link span,
[dir="rtl"] #feature .rental .link span,
[dir="rtl"] #feature .showroom .link span {
  right: 0;
  transition: right 0.3s ease-in-out;
}
[dir="rtl"] #feature .sale .link:hover span,
[dir="rtl"] #feature .rental .link:hover span,
[dir="rtl"] #feature .showroom .link:hover span {
  right: 0.25rem;
}
/* view more button style */
#feature .sale .mob-link,
#feature .rental .mob-link,
#feature .showroom .mob-link {
  text-decoration: none;
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0rem 2.5rem;
  height: 3rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: #28282b;
  border: 1px solid #28282b;
  border-radius: 0.5rem;
  cursor: pointer;
  column-gap: 0.5rem;
}
#feature .sale .mob-link span,
#feature .rental .mob-link span,
#feature .showroom .mob-link span {
  display: flex;
  position: relative;
  left: 0;
  transition: left 0.3s ease-in-out;
}
#feature .sale .mob-link:hover span,
#feature .rental .mob-link:hover span,
#feature .showroom .mob-link:hover span {
  left: 0.25rem;
}
[dir="rtl"] #feature .sale .mob-link span,
[dir="rtl"] #feature .rental .mob-link span,
[dir="rtl"] #feature .showroom .mob-link span {
  right: 0;
  transition: right 0.3s ease-in-out;
}
[dir="rtl"] #feature .sale .mob-link:hover span,
[dir="rtl"] #feature .rental .mob-link:hover span,
[dir="rtl"] #feature .showroom .mob-link:hover span {
  right: 0.25rem;
}

#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}
#about .container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
}
#about .container > div {
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)),
    url(../../assets/images/audi.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  row-gap: 1.5rem;
}
#about .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.25;
  color: #fff;
}
#about p {
  font-family: "Montserrat-Light", "NotoSansArabic-Light";
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
}
#about p br {
  display: none;
}
@media (max-width: 640px) {
  #hero {
    max-height: 62rem;
  }
}
@media (max-width: 480px) {
  #hero {
    max-height: 43rem;
  }
  #hero .hero-background {
    object-position: 42%;
  }

  #hero .header .headline h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 380px) {
  #search .filters {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  #feature .sale .mob-link,
  #feature .rental .mob-link,
  #feature .showroom .mob-link {
    font-size: 0.875rem;
    padding: 0.75rem 2.5rem;
  }
}

@media (max-width: 320px) {
  #hero {
    height: 50vh;
    max-height: 36rem;
  }
}

@media (min-width: 640px) {
  #hero {
    height: 89.25vh;
    max-height: 80rem;
  }
  #hero .header {
    padding: 0 2rem;
  }
  #search > div {
    padding: 0 2rem;
  }
  #search .heading {
    font-size: 1.875rem;
  }
  #search form {
    padding: 2rem;
  }
  #search .filters {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #search .advanced-search {
    font-size: 1rem;
  }
  #feature .sale,
  #feature .rental,
  #feature .showroom {
    padding: 0 2rem;
  }
  #feature .sale > div:nth-child(1) .heading,
  #feature .rental > div:nth-child(1) .heading,
  #feature .showroom > div:nth-child(1) .heading {
    font-size: 1.875rem;
  }
  #feature .sale .cards,
  #feature .rental .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #feature .showroom .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #about .container {
    padding: 0 2rem;
  }
}
@media (min-width: 768px) {
  #hero {
    margin-top: 5rem;
  }
  #hero .header {
    padding: 0 2.5rem;
  }
  #hero .header .headline {
    display: flex;
  }
  #hero .header .headline h1 {
    font-size: 2.5rem;
    align-self: flex-start;
    text-align: initial;
  }
  #hero .header .headline h2 {
    font-size: 1.25rem;
    align-self: flex-start;
    text-align: initial;
  }
  #hero .header button {
    font-size: 1rem;
    align-self: flex-start;
    height: 3.25rem;
  }
  #search > div {
    padding: 0 2.5rem;
  }
  #search .heading {
    font-size: 2rem;
    align-self: flex-start;
  }
  #search form > div:nth-child(3) {
    display: flex;
    justify-content: space-between;
  }
  #search .mob-search-btn {
    display: none;
  }
  #search .search-btn {
    display: initial;
  }
  #feature {
    margin-top: 4rem;
    gap: 4rem;
  }
  #feature .sale,
  #feature .rental,
  #feature .showroom {
    padding: 0 2.5rem;
    row-gap: 2rem;
  }
  #feature .sale > div:nth-child(1) .heading,
  #feature .rental > div:nth-child(1) .heading,
  #feature .showroom > div:nth-child(1) .heading {
    font-size: 2rem;
  }
  #feature .sale .mob-link,
  #feature .rental .mob-link,
  #feature .showroom .mob-link {
    display: none;
  }
  #feature .sale .link,
  #feature .rental .link,
  #feature .showroom .link {
    display: flex;
  }
  #feature .sale .cards,
  #feature .rental .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #feature .showroom .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #about .container {
    padding: 0 2.5rem;
  }
  #about p br {
    display: initial;
  }
}
@media (min-width: 800px) {
  #search .filters {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 900px) {
  #feature .sale .cards,
  #feature .rental .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #feature .showroom .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  #hero .header {
    padding: 0 3rem;
  }
  #search > div {
    padding: 0 3rem;
  }
  #search .filters {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  #feature .sale,
  #feature .rental,
  #feature .showroom {
    padding: 0 3rem;
  }
  #feature .sale .cards,
  #feature.rental .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #feature .showroom .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #about .container {
    padding: 0 3rem;
  }
  #about .heading {
    font-size: 2rem;
  }
  #about p {
    font-size: 1.125rem;
  }
}
@media (min-width: 1280px) {
  #hero .header {
    max-width: 1280px;
  }
  #search > div {
    max-width: 1280px;
  }
  #feature .sale,
  #feature .rental,
  #feature .showroom {
    max-width: 1280px;
  }
  #about .container {
    max-width: 1280px;
  }
  #feature .sale .cards,
  #feature .rental .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #feature .showroom .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  #hero .header {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #search > div {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #feature .sale,
  #feature .rental,
  #feature .showroom {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #about .container {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #feature .sale .cards,
  #feature .rental .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  #feature .showroom .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
