#additional-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
#additional-info header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#additional-info .row {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
#additional-info .row .input-group {
  position: relative;
  width: 100%;
}
#additional-info .row .input-group textarea {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  padding: 0 1rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  height: 8rem;
  padding-top: 1.125rem;
  resize: none;
  margin-top: 0.375rem;
}

#additional-info .row .input-group textarea ~ label {
  position: absolute;
  top: 1.125rem;
  left: 1.07rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  background-color: #fff;
}
[dir="rtl"] #additional-info .row .input-group textarea ~ label {
  right: 1.07rem;
}
#additional-info .row .input-group textarea:placeholder-shown ~ label {
  top: 18%;
  font-size: 0.875rem;
}
#additional-info .row .input-group textarea:focus {
  border-color: var(--primary-900);
}
#additional-info .row .input-group textarea:focus + label {
  top: 1.125rem;
  font-size: 0.75rem;
}
#additional-info .row .feature-heading {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#additional-info .row .features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
#additional-info .row .features .feature {
  display: flex;
}
#additional-info .row .features .feature input {
  display: none;
}
#additional-info .row .features .feature label {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  border: 1px solid var(--primary-300);
  padding: 1rem;
  border-radius: var(--radius-8);
  cursor: pointer;
}

#additional-info .row .features .feature input:checked + label {
  background-color: var(--primary-800);
  border: 1px solid var(--primary-800);
  color: #fff;
}
