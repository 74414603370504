#social-media {
  display: flex;
  flex-direction: column;
}
#social-media .success {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: 3rem;
  padding: 0rem 1rem;
  border: 1px solid var(--green-600);
  border-radius: var(--radius-8);
  background-color: var(--bg-green);
  margin-bottom: 1rem;
}
#social-media .success p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#social-media .success svg {
  flex-shrink: 0;
}
#social-media form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#social-media .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#social-media .row .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

#social-media .row .input-container {
  display: flex;
  width: 100%;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
  padding: 0rem 1rem;
  align-items: center;
  column-gap: 1rem;
}
#social-media .row .input-container:focus-within {
  border-color: var(--primary-900);
}
#social-media .row .input-container .icon {
  display: flex;
}
#social-media .input-group {
  position: relative;
  width: 100%;
}
#social-media .input-group label {
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-400);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  top: 0.875rem;
}

[dir="rtl"] #social-media .input-group label {
  right: 0;
}
#social-media .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#social-media .input-group input {
  border: none;
  width: 100%;
  height: 3rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#social-media .input-group input:focus {
  border-color: var(--primary-900);
}
#social-media .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

#social-media form button[type="submit"] {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3.25rem;
  padding: 0 2rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
  align-self: flex-end;
}
#social-media form button[type="submit"]:hover {
  background-color: var(--primary-800);
}

@media (min-width: 480px) {
  #social-media .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #social-media .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
