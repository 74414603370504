#email-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}
#email-modal .close-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.email-modal-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;
  max-width: 32rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: var(--radius-8);
}
.email-modal-container header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
}
.email-modal-container header .title {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--primary-900);
}
.email-modal-container header p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
.email-modal-container .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  width: 100%;
}
.email-modal-container .input-group {
  position: relative;
  width: 100%;
}
.email-modal-container .input-group label {
  position: absolute;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-400);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  top: 0.875rem;
}

[dir="rtl"] .email-modal-container .input-group label {
  right: 1rem;
}
.email-modal-container .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
.email-modal-container .input-group input {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}

.email-modal-container .input-group input:focus {
  border-color: var(--primary-900);
}
.email-modal-container .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

.email-modal-container button {
  padding: 0 3rem;
  height: 3rem;
  border-radius: var(--radius-8);
  background-color: var(--primary-900);
  color: var(--primary-50);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  transition: 0.2s ease-in-out;
  outline: none;
  border: 1px solid var(--primary-900);
}
.email-modal-container button:hover {
  background-color: var(--primary-800);
  cursor: pointer;
}
.email-modal-container button:disabled {
  opacity: 0.5;
}
