#upload-images {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
#upload-images header p:nth-child(1) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#upload-images header p:nth-child(2) {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#upload-images .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
#upload-images .row .images-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed var(--primary-300);
  border-radius: var(--radius-8);
}
#upload-images .row .images-input input {
  display: none;
}
#upload-images .row .images-input label {
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  gap: 0.25rem;
  width: 100%;
  padding: 2rem;
}
#upload-images .images-preview {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.75rem;
  margin-top: 0.5rem;
}
#upload-images .images-preview > div {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#upload-images .images-preview .image {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  position: relative;
  border-bottom: 2px solid var(--primary-900);
}
#upload-images .images-preview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#upload-images .images-preview .image button {
  outline: none;
  background-color: var(--primary-50);
  border-radius: 0.375rem;
  border: none;
  padding: 0.3rem;
  border: 0.25px solid var(--primary-300);
  position: absolute;
  display: flex;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 2;
  cursor: pointer;
}
#upload-images .images-preview .change-input {
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  overflow: hidden;
  z-index: 1;
}
#upload-images .images-preview .change-input input {
  position: absolute;
  cursor: pointer;
  display: none !important;
  background-color: aqua;
  inset: 0;
  left: 0;
  cursor: pointer;
}
#upload-images .images-preview .change-input label {
  cursor: pointer;
  position: absolute;
  inset: 0;
  left: 0;
  cursor: pointer;
}
#upload-images .images-preview .primary-img-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  background-color: #fff;
  outline: none;
  color: var(--primary-900);
  border: none;
  padding: 0.5rem 1rem;
}
#upload-images .images-preview .primary-img-btn:hover {
  cursor: pointer;
  background: var(--primary-50);
}

@media (min-width: 400px) {
  #upload-images .images-preview {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 580px) {
  #upload-images .images-preview {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #upload-images .row .images-input {
    max-width: 30rem;
  }
}
@media (min-width: 800px) {
  #upload-images .images-preview {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  #upload-images .images-preview {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
