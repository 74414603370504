#showroom-container {
  display: flex;
}

#left-side {
  display: none;
  flex-direction: column;
  width: 15.575rem;
  padding: 1rem;
  row-gap: 2rem;
  position: fixed;
  overflow-y: auto;
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  z-index: 2;
  top: 0;
  bottom: 0;
}
#left-side .logo {
  width: 8rem;
  cursor: pointer;
}
#left-side .logo img {
  width: 100%;
}

#showroom-container .overlay {
  background-color: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 4;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

#showroom-container aside {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  row-gap: 1rem;
  position: fixed;
  overflow-y: auto;
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
  background-color: #fff;
  z-index: 5;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}
[dir="rtl"] #showroom-container aside {
  transform: translateX(100%);
}
#showroom-container aside .profile-greet {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}
#showroom-container aside .profile-greet img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: var(--radius-8);
  border: 1px solid var(--primary-100);
}
#showroom-container aside .profile-greet p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
  max-width: 11rem;
}
#showroom-container aside .solid {
  border-bottom: 1px solid var(--primary-100);
}

#showroom-container .outlet {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: var(--primary-50);
  padding: 0.75rem;
  transition: filter 0.3s ease-in-out;
}
#showroom-container .outlet nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0.75rem;
  border-radius: var(--radius-8);
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
  margin-bottom: 1rem;
  z-index: 3;
}
#showroom-container .outlet nav .left {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
}
#showroom-container .outlet nav .left button {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#showroom-container .outlet nav .left .noyya-logo {
  width: 7rem;
  display: initial;
}
#showroom-container .outlet nav .left .noyya-logo img {
  width: 100%;
  height: 100%;
}
#showroom-container .outlet nav .left .profile-greet {
  display: none;
  align-items: center;
  column-gap: 0.75rem;
}
#showroom-container .outlet nav .left .profile-greet img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: var(--radius-8);
  border: 1px solid var(--primary-100);
}
#showroom-container .outlet nav .left .profile-greet p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
}

@media (min-width: 1280px) {
  #left-side {
    display: flex;
  }
  #showroom-container aside {
    display: none;
  }
  #showroom-container .overlay {
    display: none;
  }
  #showroom-container .outlet {
    margin-inline-start: 15.575rem;
  }
  #showroom-container .outlet nav .left .noyya-logo {
    display: none;
  }
  #showroom-container .outlet nav .left .profile-greet {
    display: flex;
  }
  #showroom-container .outlet nav .left button {
    display: none;
  }
}
/* @media (min-width: 1536px) {
  #showroom-container .outlet {
    max-width: calc(1536px - 15.575rem);
  }
} */
