#pricing {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
#pricing header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#pricing .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#pricing .row .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}
#pricing .row-2 {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}
#pricing .price {
  display: flex;
  column-gap: 1px;
  background-color: var(--primary-300);
  border-radius: var(--radius-8);
}
#pricing .price .price-unit {
  min-width: 7.5rem;
}
#pricing .price .price-unit .dd-header {
  border-inline-end: none;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
#pricing .row .input-group {
  position: relative;
  width: 100%;
}
#pricing .row .input-group label {
  position: absolute;
  top: 0.875rem;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
[dir="rtl"] #pricing .row .input-group label {
  right: 1rem;
}
#pricing .row .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#pricing .row .input-group input {
  border: 1px solid var(--primary-300);
  border-inline-start: none;
  border-start-end-radius: var(--radius-8);
  border-end-end-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#pricing .row .input-group input:focus {
  border-color: var(--primary-900);
}
#pricing .row .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}
#pricing .price-shown {
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
}
#pricing .price-shown p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#pricing .price-shown .btns {
  display: flex;
  column-gap: 0.75rem;
  width: fit-content;
}
#pricing .price-shown .radio-group {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}
#pricing .price-shown .radio-group input {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
#pricing .price-shown .radio-group label {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  color: var(--primary-900);
  cursor: pointer;
  line-height: 1.25;
}

@media (min-width: 500px) {
  #pricing .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #pricing .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
