#edit-plate {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: var(--radius-8);
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
}

#edit-plate .edit-heading {
  margin-bottom: 1rem;
}
#edit-plate .edit-heading p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#edit-plate form {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#edit-plate .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#edit-plate .row .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#edit-plate .price {
  display: flex;
  column-gap: 1px;
  background-color: var(--primary-300);
  border-radius: var(--radius-8);
}
#edit-plate .price .price-unit {
  min-width: 8rem;
}
#edit-plate .price .price-unit .dd-header {
  border-inline-end: none;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
#edit-plate .row .input-group {
  position: relative;
  width: 100%;
}
#edit-plate .row .input-group label {
  position: absolute;
  top: 0.875rem;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
[dir="rtl"] #edit-plate .row .input-group label {
  right: 1rem;
}
#edit-plate .row .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#edit-plate .price .input-group input,
#edit-plate .row .input-group input {
  border: 1px solid var(--primary-300);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#edit-plate .row .input-group .plate-number {
  border-radius: var(--radius-8);
}
#edit-plate .price .input-group input {
  border-inline-start: none;
  border-start-end-radius: var(--radius-8);
  border-end-end-radius: var(--radius-8);
}

#edit-plate .row .input-group input:focus {
  border-color: var(--primary-900);
}
#edit-plate .row .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

#edit-plate .price-shown {
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
}
#edit-plate .price-shown p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#edit-plate .price-shown .btns {
  display: flex;
  column-gap: 0.75rem;
  width: fit-content;
}
#edit-plate .price-shown .radio-group {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}
#edit-plate .price-shown .radio-group input {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
#edit-plate .price-shown .radio-group label {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  color: var(--primary-900);
  cursor: pointer;
  line-height: 1.25;
}
#edit-plate .action-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  column-gap: 1rem;
  margin-top: 1rem;
}
#edit-plate .cancel {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: #fff;
  color: var(--primary-900);
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  cursor: pointer;
}
#edit-plate .cancel:hover {
  outline: 1px solid var(--primary-900);
}
#edit-plate .update {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  width: fit-content;
  height: 3rem;
  padding: 0 2rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#edit-plate .update:hover {
  background-color: var(--primary-800);
}
@media (min-width: 500px) {
  #edit-plate .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #edit-plate .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
