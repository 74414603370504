.f-disabled {
  pointer-events: none;
  opacity: 0.65;
}
.f-disabled .dd-header p,
.f-disabled .dd-header svg {
  opacity: 0.65;
}
#filter-drop-down {
  width: 100%;
}
#filter-drop-down .dd-container {
  position: relative;
  cursor: pointer;
}
#filter-drop-down .dd-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: #fff;
  padding: 0 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  /* border: 1px solid var(--primary-300); */

  border-radius: var(--radius-8);
}
#filter-drop-down .dd-header p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.875rem;
}
#filter-drop-down .dd-header svg {
  transition: all 0.3s ease-in-out;
}
#filter-drop-down .dd-container label {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 1.07rem;
  color: var(--primary-500);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.5;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
[dir="rtl"] #filter-drop-down .dd-container label {
  right: 1.07rem;
}
#filter-drop-down .ddl-container {
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-height: 11.5rem;
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}
#filter-drop-down .dd-list {
  width: 100%;
  text-align: initial;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#filter-drop-down .dd-list:first-child {
  padding-top: 0.25rem;
}
#filter-drop-down .dd-list li {
  display: flex;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--primary-900);
}
#filter-drop-down .dd-list li:hover {
  background-color: var(--primary-50);
  color: var(--primary-900);
}
#filter-drop-down .dd-list .selected {
  background-color: var(--primary-900);
  color: #fff;
}
