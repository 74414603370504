#step-three {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.75rem;
}
#step-three > p:nth-child(1) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  margin-bottom: 0.375rem;
}
#step-three .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
}

#step-three .row .images-input {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed var(--primary-300);
  border-radius: var(--radius-8);
}
#step-three .row .images-input input {
  display: none;
}
#step-three .row .images-input label {
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  gap: 0.25rem;
  width: 100%;
  padding: 2rem;
}
#step-three .images-preview {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem;
  margin-top: 0.5rem;
}
#step-three .images-preview > div {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#step-three .images-preview > div button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  background-color: #fff;
  outline: none;
  color: var(--primary-900);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
#step-three .images-preview > div button span {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.675rem;
  line-height: 1.25;
}
#step-three .images-preview > div button div svg {
  flex-shrink: 0;
}
#step-three .images-preview > div button:hover {
  background-color: var(--primary-50);
}
#step-three .images-preview .image {
  width: 100%;
  height: 7rem;
  overflow: hidden;
  position: relative;
  border-bottom: 2px solid var(--primary-900);
  border-top: 2px solid var(--primary-900);
}
#step-three .images-preview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#step-three .images-preview .image button {
  outline: none;
  background-color: var(--primary-50);
  border-radius: 0.375rem;
  border: none;
  padding: 0.3rem;
  border: 0.25px solid var(--primary-300);
  position: absolute;
  display: flex;
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
  z-index: 2;
}

#step-three .images-preview .change-input {
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  overflow: hidden;
  z-index: 1;
}
#step-three .images-preview .change-input input {
  position: absolute;
  cursor: pointer;
  display: none !important;
  inset: 0;
  left: 0;
  cursor: pointer;
}
#step-three .images-preview .change-input label {
  cursor: pointer;
  position: absolute;
  inset: 0;
  left: 0;
  cursor: pointer;
}

#step-three .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  width: 100%;
  height: 3.25rem;
  background-color: var(--primary-900);
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#step-three .btn:hover {
  background-color: var(--primary-800);
}

@media (min-width: 400px) {
  #step-three > p:nth-child(1) {
    font-size: 1rem;
  }
  #step-three .row .images-input label {
    font-size: 0.875rem;
  }
  #step-three .images-preview > div button span {
    font-size: 0.75rem;
  }
}
@media (min-width: 500px) {
  #step-three .images-preview {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
