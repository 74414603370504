#terms-conditions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#terms-conditions .container {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
}
#terms-conditions .container h1 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.75rem;
}
#terms-conditions .container h2 {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.25rem;
}
#terms-conditions .container h3 {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
}
#terms-conditions .container p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
}
#terms-conditions .container section {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#terms-conditions .container section h2 {
  margin-bottom: 0.25rem;
}
#terms-conditions .container a {
  color: var(--primary-900);
}
@media (min-width: 640px) {
  #terms-conditions .container {
    padding: 2rem;
  }
  #terms-conditions .container h1 {
    font-size: 2rem;
  }
  #terms-conditions .container h2 {
    font-size: 1.5rem;
  }
  #terms-conditions .container h3 {
    font-size: 1rem;
  }
  #terms-conditions .container p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  #terms-conditions .container {
    padding: 2rem 2.5rem;
  }
}
@media (min-width: 1024px) {
  #terms-conditions {
    margin-top: 5rem;
  }
  #terms-conditions .container {
    padding: 2rem 3rem;
  }
}
@media (min-width: 1280px) {
  #terms-conditions .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #terms-conditions .container {
    padding: 2rem 5rem;
    max-width: 1536px;
  }
}
