#register {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#register .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2.5rem 1rem;
  row-gap: 2.5rem;
}
#register .container .logo img {
  width: 15rem;
}
#register .container .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#register .container .top button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
[dir="rtl"] #register .container .top button {
  transform: scaleX(-1);
}
#register .container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.75rem;
}
#register .input-group {
  position: relative;
  width: 100%;
}
#register .input-group label {
  position: absolute;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  top: 0.875rem;
}

[dir="rtl"] #register .input-group label {
  right: 1rem;
}
#register .input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#register .input-group input {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#register .input-group input:focus {
  border-color: var(--primary-900);
}
#register .input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

@media (min-width: 640px) {
  #register .container {
    padding: 2.5rem 2rem;
  }
  #register .container form {
    width: 38rem;
  }
}
@media (min-width: 768px) {
  #register .container {
    padding: 2.5rem 2.5rem;
  }
}
@media (min-width: 1024px) {
  #register .container {
    padding: 2.5rem 3rem;
  }
}
@media (min-width: 1280px) {
  #register .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #register .container {
    padding: 2.5rem 5rem;
    max-width: 1536px;
  }
}
