#a-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)),
    url(../../assets/images/aboutUsPic.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 40% 75%;
  margin-top: 4rem;
  height: 40vh;
}
#a-hero header {
  width: 100%;
  padding: 0 1rem;
}
#a-hero header h1 {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25;
  color: #fff;
}
#a-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#a-feature .container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem;
  width: 100%;
  row-gap: 1.5rem;
  column-gap: 1.5rem;
}
#a-feature .container .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 2.5rem;
  row-gap: 1.5rem;
}
#a-feature .container .last-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#a-feature .container .row div {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#a-feature .container .heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  color: var(--primary-900);
}
#a-feature .container .h2 {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#a-feature .container .desc {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--primary-900);
  margin-top: 0.5rem;
}
@media (min-width: 640px) {
  #a-hero {
    background-position: 0 75%;
    height: 45vh;
  }
  #a-hero header {
    padding: 2rem;
  }
  #a-feature .container {
    padding: 2.5rem 2rem;
  }
  #a-feature .container .heading {
    font-size: 1.5rem;
  }
  #a-feature .container .desc {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  #a-hero header {
    padding: 0 2.5rem;
  }
  #a-hero header h1 {
    font-size: 2.5rem;
  }
  #a-feature .container {
    padding: 2.5rem 2.5rem;
  }
}
@media (min-width: 1024px) {
  #a-hero {
    margin-top: 5rem;
  }
  #a-hero header {
    padding: 0 3rem;
  }
  #a-feature .container {
    padding: 2.5rem 3rem;
  }
}
@media (min-width: 1160px) {
  #a-feature .container {
    row-gap: 2rem;
  }
  #a-feature .container .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 2rem;
  }
}
@media (min-width: 1280px) {
  #a-hero header {
    max-width: 1280px;
  }
  #a-feature .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  #a-hero header {
    padding: 0 5rem;
    max-width: 1536px;
  }
  #a-feature .container {
    padding: 2.5rem 5rem;
    max-width: 1536px;
  }
}
