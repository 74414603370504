/* Mobile UI for Sort */
.mobile-sort {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 9999;
  transition: all 0.5s ease-in-out;
}
.mobile-sort header {
  width: 100%;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgb(1 1 1 / 10%);
  padding: 0 1rem;
}
.mobile-sort header p {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.375rem;
  line-height: 1.25;
  color: #28282b;
}
.mobile-sort header button {
  border: none;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--primary-50);
  display: flex;
  cursor: pointer;
}
.mobile-sort header button:hover {
  background-color: var(--primary-200);
}
.mobile-sort header button:hover svg {
  fill: #fff;
}
.mobile-sort .sort-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
}

.mobile-sort .sort-container .radio-btns {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
.mobile-sort .sort-container .radio-btns div {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}
.mobile-sort .sort-container .radio-btns div input {
  width: 1rem;
  height: 1rem;
  filter: hue-rotate(180deg);
  accent-color: #28282b;
  cursor: pointer;
}
.mobile-sort .sort-container .radio-btns div label {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  color: #28282b;
  cursor: pointer;
}

@media (min-width: 640px) {
  .mobile-sort .sort-container {
    padding: 1.5rem 2rem;
  }
  .mobile-sort header {
    padding: 0 2rem;
  }
}
@media (min-width: 768px) {
  .mobile-sort .sort-container {
    padding: 1.5rem 2.5rem;
  }
  .mobile-sort header {
    padding: 0 2.5rem;
  }
}
@media (min-width: 1024px) {
  .mobile-sort {
    display: none;
  }
}
