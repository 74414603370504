.sh-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(50, 50);
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: #000;
  padding: 0.75rem 0.75rem;
  display: flex;
  flex-direction: column;
}
.sh-full-screen-btn {
  position: relative;
  cursor: pointer;
  z-index: 1001;
}
.sh-full-screen-btn svg {
  position: absolute;
  top: -3rem;
  right: 0.75rem;
  cursor: pointer;
}

.showroom-carousel .showroom-images-slider {
  margin: 0;
  width: 100%;
  height: 26rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
}
.sh-full-screen .showroom-images-slider {
  height: 100%;
  margin-bottom: 0.5rem;
  border: 0.75px solid #fff;
  border-radius: 0;
}
.showroom-carousel .showroom-images-slider .swiper-slide {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
.showroom-carousel .showroom-images-slider .swiper-slide-active {
  margin-inline-end: 10px;
}
.showroom-carousel .showroom-images-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sh-full-screen .showroom-images-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.showroom-carousel .showroom-images-slider-thumbs {
  height: 4rem;
}
.sh-full-screen .showroom-images-slider-thumbs {
  height: 3.5rem;
  width: 100%;
}
.showroom-carousel .showroom-images-slider-thumbs .swiper-slide {
  border: 0.75px solid var(--primary-300);
  border-radius: 0.25rem;
  overflow: hidden;
  opacity: 0.8;
}
.sh-full-screen .showroom-images-slider-thumbs .swiper-slide {
  border: 0.75px solid transparent;
  border-radius: 0rem;
  opacity: 0.7;
}
.showroom-carousel .showroom-images-slider-thumbs .swiper-wrapper {
  column-gap: 0.5rem;
}
.sh-full-screen .showroom-images-slider-thumbs .swiper-wrapper {
  column-gap: 0.5rem;
}
.showroom-carousel .swiper-button-next,
.showroom-carousel .swiper-button-prev {
  color: var(--primary-900);
  font-weight: 700;
  border-radius: 0.125rem;
  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.showroom-carousel .swiper-button-next::after,
.showroom-carousel .swiper-button-prev::after {
  font-size: 1rem;
  font-weight: 700;
}
.showroom-carousel .showroom-images-slider-thumbs .swiper-slide-thumb-active {
  border: 2px solid var(--primary-900);
  opacity: 1;
}
.sh-full-screen .showroom-images-slider-thumbs .swiper-slide-thumb-active {
  border: 0.75px solid #fff;
  opacity: 1;
}

.showroom-carousel .showroom-images-slider-thumbs .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sh-full-screen .showroom-images-slider-thumbs .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sh-full-screen {
  padding: 1rem;
}
@media (min-width: 640px) {
  .sh-full-screen .showroom-images-slider-thumbs {
    height: 5rem;
  }
}
@media (min-width: 768px) {
  .sh-full-screen .showroom-images-slider-thumbs {
    height: 6rem;
  }
}
@media (min-width: 1024px) {
  .showroom-carousel .showroom-images-slider-thumbs {
    height: 5rem;
  }
  .sh-full-screen .showroom-images-slider {
    height: 48rem;
  }
  .sh-full-screen .showroom-images-slider-thumbs {
    height: 7rem;
  }
}
