#sd-hero {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}
#sd-hero .showroom {
  width: 100%;
  padding: 0 1rem;
}
#sd-hero .showroom header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0rem;
}
#sd-hero .showroom header .leftside {
  display: flex;
  column-gap: 1rem;
}
#sd-hero .showroom header .showroom-logo {
  width: 5rem;
  height: 5rem;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
  flex-shrink: 0;
}
#sd-hero .showroom header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#sd-hero .showroom header .showroom-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#sd-hero .showroom header .showroom-info h1 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  color: var(--primary-900);
}
#sd-hero .showroom header .showroom-info .showroom-type,
#sd-hero .showroom header .showroom-info .city {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  white-space: wrap;
}
#sd-hero .showroom header .showroom-info .showroom-type svg,
#sd-hero .showroom header .showroom-info .city svg {
  flex-shrink: 0;
}
#sd-hero .showroom header .showroom-info .showroom-type h2 {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  color: var(--primary-500);
}
#sd-hero .showroom header .showroom-info .city h3 {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 400;
  color: var(--primary-500);
}

#sd-hero header .share-btn {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0.125rem;
}

#sd-hero .showroom .showroom-details {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  column-gap: 2rem;
}
#sd-hero .showroom .showroom-details .col {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

#sd-hero .showroom .showroom-details .row {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
#sd-hero .showroom .showroom-details .row .heading-dp {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  width: fit-content;
  cursor: pointer;
}
#sd-hero .showroom .showroom-details .row .heading-dp svg {
  transition: all 0.3s ease-in-out;
}
#sd-hero .showroom .showroom-details .row .heading {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#sd-hero .showroom .showroom-details .row p,
#sd-hero .showroom .showroom-details .row a {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#sd-hero .showroom .showroom-details .row a {
  text-decoration: none;
}
#sd-hero .showroom .showroom-details .row a:hover {
  text-decoration: underline;
}
#sd-hero .showroom .social-accounts {
  display: flex;
  column-gap: 1rem;
  margin-top: 0.25rem;
}
#sd-hero .showroom .social-accounts a svg {
  transition: transform 0.5s ease-in-out;
}
#sd-hero .showroom .social-accounts a:hover svg {
  transform: scale(1.1);
}
#sd-hero .showroom iframe {
  width: 100%;
  height: 20rem;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
}
#sd-hero .showroom table {
  margin-top: 0.5rem;
  min-width: fit-content;
  border-collapse: collapse;
  border-radius: var(--radius-8);
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.25);
  background-color: #fff;
}
#sd-hero .showroom table thead {
  border-bottom: 1px solid var(--primary-100);
}
#sd-hero .showroom table thead th {
  padding: 0.75rem 0.75rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-900);
  text-align: start;
  font-weight: 500;
}
#sd-hero .showroom table tbody tr {
  border-bottom: 1px solid var(--primary-50);
}
#sd-hero .showroom table tbody td {
  padding: 0.5rem 0.75rem;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 1rem;
  line-height: 1.25;
  color: var(--primary-500);
  text-align: start;
}

#sd-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
#sd-feature .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 1rem;
}
#sd-feature .header h3 {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #28282b;
}
#sd-feature .header > div {
  display: none;
}
#sd-feature .sale {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  gap: 2rem;
}
#sd-feature .sale .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}
/* pagination */
#sd-feature .paginationBtns {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
#sd-feature .paginationBtns a {
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-300);
  color: var(--primary-500);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
#sd-feature .paginationBtns .next a,
#sd-feature .paginationBtns .previous a {
  width: auto;
}
#sd-feature .paginationBtns a:hover {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}
#sd-feature .paginationActive a {
  background-color: #28282b;
  border-color: #28282b;
  color: #fff;
}
#sd-feature .rental {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  gap: 2rem;
}
#sd-feature .rental .cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
}

#sd-feature .mobile {
  display: flex;
  width: 100%;
  position: sticky;
  top: 4rem;
  z-index: 49;
  background: #fff;
}
#sd-feature .mobile .btn {
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
#sd-feature .mobile .btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  height: 3rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  background: #fff;
  color: var(--primary-900);
  border: none;
  cursor: pointer;
}
.mobile-fade-in {
  animation: fade-in 200ms ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 480px) {
  #sd-hero .showroom header .showroom-logo {
    width: 6rem;
    height: 6rem;
  }
  #sd-hero .showroom header .showroom-info h1 {
    font-size: 1.25rem;
  }
  #sd-hero .showroom header .showroom-info .showroom-type h2 {
    font-size: 1rem;
  }
  #sd-hero .showroom header .showroom-info .city h3 {
    font-size: 1rem;
  }
  #sd-hero .showroom .showroom-details .row .heading {
    font-size: 1.125rem;
  }

  #sd-hero header .share-btn svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
@media (min-width: 640px) {
  #sd-hero .showroom {
    padding: 0 2rem;
  }

  #sd-hero .showroom header .showroom-logo {
    width: 8rem;
    height: 8rem;
  }
  #sd-hero .showroom header .showroom-info h1 {
    font-size: 1.75rem;
  }
  #sd-hero .showroom header .showroom-info .showroom-type h2 {
    font-size: 1.25rem;
  }
  #sd-feature .header {
    padding: 2.5rem 2rem;
  }
  #sd-feature .sale {
    padding: 0 2rem;
  }
  #sd-feature .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #sd-feature .paginationBtns {
    column-gap: 0.75rem;
  }
  #sd-feature .paginationBtns a {
    padding: 0.75rem;
    height: 2.5rem;
    width: 2.5rem;
  }
}
@media (min-width: 768px) {
  #sd-hero {
    margin-top: 5rem;
  }
  #sd-hero .showroom {
    padding: 0 2.5rem;
  }
  #sd-feature .mobile {
    top: 5rem;
  }
  #sd-feature .header {
    padding: 2.5rem 2.5rem;
  }
  #sd-feature .header h3 {
    font-size: 1.75rem;
  }
  #sd-feature .sale {
    padding: 0 2.5rem;
  }
  #sd-feature .sale .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #sd-feature .rental {
    padding: 0 2.5rem;
  }
  #sd-feature .rental .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  #sd-hero .showroom {
    padding: 0 3rem;
  }
  #sd-hero .showroom .showroom-details {
    flex-direction: row;
  }
  #sd-hero .showroom .showroom-details .col {
    width: 50%;
  }
  #sd-feature .mobile {
    display: none;
  }
  #sd-feature .header {
    padding: 2.5rem 3rem;
  }
  #sd-feature .header > div {
    display: initial;
  }
  #sd-feature .sale {
    padding: 0 3rem;
  }
  #sd-feature .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #sd-feature .rental {
    padding: 0 3rem;
  }
  #sd-feature .rental .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  #sd-hero .showroom {
    max-width: 1280px;
  }
  #sd-feature .header {
    max-width: 1280px;
  }
  #sd-feature .sale {
    max-width: 1280px;
  }
  #sd-feature .sale .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  #sd-feature .rental {
    max-width: 1280px;
  }
  #sd-feature .rental .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  #sd-hero .showroom {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #sd-feature .header {
    max-width: 1536px;
    padding: 1rem 5rem;
  }
  #sd-feature .sale {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #sd-feature .sale .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  #sd-feature .rental {
    max-width: 1536px;
    padding: 0 5rem;
  }
  #sd-feature .rental .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
