/* Font-face declarations */
@font-face {
  font-family: Montserrat-Light;
  src: url(/Fonts/Montserrat-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url(/Fonts/Montserrat-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(/Fonts/Montserrat-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(/Fonts/Montserrat-SemiBold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(/Fonts/Montserrat-Bold.ttf);
  font-display: swap;
}

/* Vazirmatn font */
@font-face {
  font-family: NotoSansArabic-Light;
  src: url(/Fonts/Vazirmatn-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: NotoSansArabic-Regular;
  src: url(/Fonts/Vazirmatn-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: NotoSansArabic-Medium;
  src: url(/Fonts/Vazirmatn-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: NotoSansArabic-SemiBold;
  src: url(/Fonts/Vazirmatn-SemiBold.ttf);
  font-display: swap;
}
@font-face {
  font-family: NotoSansArabic-Bold;
  src: url(/Fonts/Vazirmatn-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Fe-Normal;
  src: url(/Fonts/Fe-Normal.ttf);
  font-display: swap;
}
