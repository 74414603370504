#upload-showroom-images .images-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  border: 1px dashed var(--primary-300);
  border-radius: var(--radius-8);
}
#upload-showroom-images .images-input input {
  display: none;
}
#upload-showroom-images .images-input label {
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  gap: 0.25rem;
  width: 100%;
  padding: 2rem;
}
#upload-showroom-images .images-preview {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.75rem;
  margin-top: 0.5rem;
}
#upload-showroom-images .images-preview > div {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#upload-showroom-images .images-preview > div button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  background-color: #fff;
  outline: none;
  color: var(--primary-900);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: nowrap;
}
#upload-showroom-images .images-preview > div button:hover {
  background-color: var(--primary-50);
}
#upload-showroom-images .images-preview > div button svg {
  flex-shrink: 0;
}
#upload-showroom-images .images-preview .image {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  position: relative;
  border-bottom: 2px solid var(--primary-900);
  border-top: 2px solid var(--primary-900);
}
#upload-showroom-images .images-preview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#upload-showroom-images .images-preview .image button {
  outline: none;
  background-color: var(--primary-50);
  border-radius: 0.375rem;
  border: none;
  padding: 0.3rem;
  border: 0.25px solid var(--primary-300);
  position: absolute;
  display: flex;
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
  z-index: 2;
}

@media (min-width: 400px) {
  #upload-showroom-images .images-preview {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 580px) {
  #upload-showroom-images .images-preview {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 700px) {
  #upload-showroom-images .images-preview {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  #upload-showroom-images .images-preview {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
