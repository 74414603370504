#lang .dd-container {
  position: relative;
  cursor: pointer;
  width: 100%;
}
#lang .dd-header {
  font-family: "Montserrat-Regular", "NotoSansArabic-Light";
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #28282b32;
  border-radius: 0.5rem;
  gap: 1rem;
  padding: 0 1rem;
  height: 2.575rem;
  line-height: 1.25;
}
#lang .dd-header p {
  font-size: 0.875rem;
  color: var(--primary-900);
}
#lang .dd-header svg {
  transition: all 0.3s ease-in-out;
}
#lang .ddl-container {
  position: absolute;
  margin-top: 1.5px;
  border-radius: 0.5rem;
  background: #fff;
  border: 1px solid #28282b32;
  width: 100%;
  line-height: 1.25;
}
#lang .dd-list {
  font-family: "Montserrat-Medium", "NotoSansArabic-Regular";
  color: #28282b;
  font-size: 0.875rem;
}
#lang .dd-list li {
  width: 100%;
  list-style: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 0.5rem;
}
#lang .dd-list li:hover {
  background-color: #f6f6f7;
}

@media (min-width: 1024px) {
  #lang .dd-header p {
    font-size: 1rem;
  }
  #lang .dd-list {
    font-size: 1rem;
  }
}
