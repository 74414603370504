.showroom .card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.875rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.showroom .card .header {
  position: relative;
  width: 100%;
  margin-bottom: 0.375rem;
  border-radius: 0.875rem 0.875rem 0rem 0rem;
}
.showroom .card-img-container {
  position: relative;
  padding-bottom: 75%;
  width: 100%;
  overflow: hidden;
  border-radius: 0.875rem 0.875rem 0rem 0rem;
}

.showroom .header .card-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.875rem 0.875rem 0rem 0rem;
  transition: all 0.3s ease-in-out;
  filter: brightness(95%);
}
.showroom .card:hover .card-img {
  transform: scale(1.03);
  filter: brightness(80%);
  border-radius: 0.875rem 0.875rem 0 0;
}
.showroom .header .showroom-logo {
  background-color: #fff;
  width: 5rem;
  height: 5rem;
  position: absolute;
  bottom: -1rem;
  border-radius: 0.5rem;
  margin-inline-start: 1.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}
.showroom .header .showroom-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: var(--radius-8);
}

.showroom .card > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
}
.showroom .card > div:nth-child(2) > .showroom-name {
  width: 100%;
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--primary-900);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.showroom .card .solid {
  border-bottom: 1px solid #e4dfdfcb;
  border-radius: 0.25rem;
}
.showroom .card-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--primary-900);
  gap: 0.5rem;
}
.showroom .card-footer div {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.showroom .card-footer div p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (min-width: 450px) {
  .showroom .card-img-container {
    padding-bottom: 65%;
  }
}
@media (min-width: 640px) {
  .showroom .card-img-container {
    padding-bottom: 75%;
  }
}
