#step-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
#step-one > p:nth-child(1) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  margin-bottom: 0.375rem;
  align-self: center;
}
#step-one .first-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  font-size: 1rem;
  color: var(--primary-900);
}
#step-one .radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-8);
  margin-top: 0.25rem;
}
#step-one .first-row p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  align-self: center;
}
#step-one .first-row .error {
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--red-600);
  word-wrap: break-word;
  width: auto;
}
#step-one .radio-group div {
  width: 30%;
  display: flex;
}
#step-one .radio-group div input {
  display: none;
}
#step-one .radio-group div label {
  cursor: pointer;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  border: 1px solid var(--primary-300);
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
}
#step-one .sale-label {
  border-start-start-radius: var(--radius-8);
  border-end-start-radius: var(--radius-8);
}
#step-one .rental-label {
  border-start-end-radius: var(--radius-8);
  border-end-end-radius: var(--radius-8);
}
#step-one .radio-group div input:checked + label {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  color: var(--primary-900);
  border-color: var(--primary-900);
  box-shadow: inset 0px 0px 0px 0.5px var(--primary-900);
}
#step-one .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
#step-one .row .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

#step-one .row .password {
  display: flex;
  position: relative;
  width: 100%;
}
#step-one .row .password button {
  width: fit-content;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
[dir="rtl"] #step-one .row .password button {
  left: 1rem;
  right: auto;
}
#step-one .phone-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  padding: 0 1rem;
  column-gap: 1rem;
}
#step-one .phone-input-container:focus-within {
  border: 1px solid var(--primary-900);
}
#step-one .phone-input-container .country {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
}
#step-one .phone-input-container img {
  width: 1.5rem;
}
#step-one .phone-input-container p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}

#step-one .phone-input-container .phone-input-group {
  position: relative;
  width: 100%;
}
#step-one .phone-input-container label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-400);
  pointer-events: none;
  transition: 0.2s ease-in-out;
  left: 0;
  right: auto;
}
[dir="rtl"] #step-one .phone-input-container label {
  left: auto;
  right: 0;
}

#step-one .phone-input-container input {
  border: none;
  padding-top: 0.75rem;
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
[dir="rtl"] #step-one .phone-input-container input {
  text-align: right;
}

#step-one .phone-input-container input:focus {
  border-color: var(--primary-900);
}
#step-one .phone-input-container input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}
#step-one .phone-input-container input:not(:placeholder-shown) + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

#step-one .agree {
  width: 100%;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#step-one .link {
  width: 100%;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  cursor: pointer;
  text-decoration: none;
}
#step-one .link:hover {
  text-decoration: underline;
}
#step-one .signin {
  margin-top: 0.375rem;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  align-self: center;
}
#step-one button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  width: 100%;
  height: 3.25rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#step-one button:hover {
  background-color: var(--primary-800);
}

@media (min-width: 400px) {
  #step-one > p:nth-child(1) {
    font-size: 1rem;
  }
}
@media (min-width: 500px) {
  #step-one .row {
    flex-direction: row;
  }
}
