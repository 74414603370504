#share-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  z-index: 1002;
}
#share-modal .close-btn {
  display: flex;
  align-self: flex-end;
  background: none;
  border: none;
  cursor: pointer;
}
.share-modal-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  background-color: #fff;
  padding: 1rem;
  row-gap: 0.25rem;
  border-radius: var(--radius-8);
}
.share-modal-container .title {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--primary-900);
  margin-bottom: 1rem;
}
.share-modal-container .share-modal-options {
  display: flex;
  justify-content: center;
  row-gap: 0.75rem;
  column-gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
}
.share-modal-container .share-modal-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
}
.share-modal-container .share-modal-options .react-share__ShareButton,
.share-modal-container .share-modal-options .option .copy-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--radius-8);
  border: none;
}
.share-modal-container .share-modal-options .option .copy-link {
  background-color: var(--primary-900);
}
.share-modal-container .share-modal-options .option p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
}
