#sort {
  width: 100%;
}
#sort .dd-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}
#sort .dd-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: #fff;
  padding: 0 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  border-radius: var(--radius-8);
}
#sort .dd-header p {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.875rem;
}
#sort .dd-header svg {
  transition: all 0.3s ease-in-out;
}
#sort .dd-container label {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 1.07rem;
  color: var(--primary-500);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
[dir="rtl"] #sort .dd-container label {
  right: 1.07rem;
}
#sort .ddl-container {
  margin-top: 1px;
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-height: 11.5rem;
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-8);
}
#sort .dd-list {
  text-align: initial;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#sort .dd-list:first-child {
  padding-top: 0.25rem;
}
#sort .dd-list li {
  display: flex;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--primary-900);
}
#sort .dd-list li:hover {
  background-color: var(--primary-50);
  color: var(--primary-900);
}
#sort .dd-list .selected {
  background-color: var(--primary-900);
  color: #fff;
}

@media (min-width: 400px) {
  #sort .dd-container {
    width: 10rem;
  }
}
@media (min-width: 580px) {
  #sort .dd-container {
    width: 14rem;
  }
}
