#progress-bar {
  width: 100%;
  position: relative;
}
#progress-bar span {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#progress-bar .barmarker-container {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0.5rem;
}
#progress-bar .barmarker-container .barmarker {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  display: grid;
  width: 100%;
  place-content: center;
}

#progress-bar .barmarker-container .barmarker:is(:not(:last-child)) {
  border-right: 1px solid var(--primary-300);
}
#progress-bar progress {
  width: 100%;
  height: 0.75rem;
  margin-bottom: 1rem;
  appearance: none;
  -webkit-appearance: none;
}
#progress-bar progress::-webkit-progress-bar {
  background-color: var(--primary-50);
  border-radius: 0.25rem;
}
#progress-bar progress::-webkit-progress-value {
  background-color: var(--primary-800);
  border-radius: 0.25rem;
}
