#step-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.75rem;
}
#step-two > p:nth-child(1) {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  margin-bottom: 0.375rem;
}
#step-two .row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}
#step-two .row .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#step-two .input-group input,
#step-two .input-group textarea {
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#step-two .input-group textarea {
  height: 8rem;
  padding-top: 1.275rem;
  resize: none;
}
#step-two .input-group textarea ~ label {
  top: 0.875rem;
  font-size: 0.75rem;
  background-color: #fff;
}
#step-two .input-group textarea:placeholder-shown ~ label {
  top: 15%;
  font-size: 0.875rem;
}
#step-two .input-group input:focus,
#step-two .input-group textarea:focus {
  border-color: var(--primary-900);
}
#step-two .input-group input:focus + label,
#step-two .input-group textarea:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}
#step-two .address {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#step-two .address > p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#step-two .address .map {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
#step-two .opening-times {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#step-two .opening-times > p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#step-two .opening-times .days {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  padding: 1rem;
}

#step-two .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1rem;
  line-height: 1.25;
  width: 100%;
  height: 3.25rem;
  background-color: #28282b;
  color: #fff;
  border: none;
  border-radius: var(--radius-8);
  cursor: pointer;
}
#step-two .btn:hover {
  background-color: var(--primary-800);
}

@media (min-width: 400px) {
  #step-two > p:nth-child(1) {
    font-size: 1rem;
  }
}
@media (min-width: 500px) {
  #step-two .row {
    flex-wrap: nowrap;
  }
}
