#settings {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  background-color: #fff;
  border-radius: var(--radius-8);
  row-gap: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
}
#settings .settings-heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  line-height: 1.25;
  color: var(--primary-900);
}
