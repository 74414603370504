#dashboard {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0.75rem 1rem;
  background-color: #fff;
  border-radius: var(--radius-8);
  box-shadow: 0px 1px 4px 0px rgba(40, 40, 43, 0.15);
}
#dashboard header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  row-gap: 1rem;
}
#dashboard header .dashboard-heading {
  font-family: "Montserrat-SemiBold", "NotoSansArabic-SemiBold";
  font-size: 1.5rem;
  line-height: 1.25;
  color: var(--primary-900);
}

#dashboard .row-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
}

@media (min-width: 400px) {
  #dashboard header {
    flex-direction: row;
  }
}
@media (min-width: 500px) {
  #dashboard .row-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  #dashboard .row-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
