#basic-info-rent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
#basic-info-rent header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#basic-info-rent .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#basic-info-rent .row .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}
#basic-info-rent .row .date-input-group {
  position: relative;
  width: 100%;
  height: 3rem;
  border: 1px solid var(--primary-300);
  border-radius: var(--radius-8);
  display: flex;
  overflow: hidden;
}
#basic-info-rent .row .date-input-group label {
  position: absolute;
  top: 0.875rem;
  left: 1rem;
  transform: translateY(-50%);
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.75rem;
  line-height: 1.25;
  color: var(--primary-500);
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
[dir="rtl"] #basic-info-rent .row .date-input-group label {
  right: 1rem;
}

#basic-info-rent .row .date-input-group input:placeholder-shown + label {
  top: 50%;
  font-size: 0.875rem;
}
#basic-info-rent .row .date-input-group input {
  border: 1px solid var(--primary-300);
  border: none;
  border-radius: var(--radius-8);
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  padding-top: 0.75rem;
  outline: none;
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-900);
  background-color: #fff;
  position: absolute;
  inset: 0;
  display: flex;
}

#basic-info-rent .row .date-input-group:focus-within {
  border-color: var(--primary-900);
}
#basic-info-rent .row .date-input-group input:focus + label {
  top: 0.875rem;
  font-size: 0.75rem;
}

#basic-info-rent .body-style-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#basic-info-rent .body-style-row .body-style-heading {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#basic-info-rent .body-styles {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}
#basic-info-rent .body-styles .body-style {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--primary-500);
  border-radius: 0.25rem;
  padding: 1rem 2rem 2rem 2rem;
  width: 100%;
  box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.1);
  cursor: pointer;
}
#basic-info-rent .body-styles .body-style .body-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 0.25rem;
  height: 3rem;
}
#basic-info-rent .body-styles .body-style img {
  pointer-events: none;
  width: 5.5rem;
}
#basic-info-rent .body-styles .body-style input {
  display: none;
}
#basic-info-rent .body-styles .body-style label {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  inset: 0;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0.75rem;
  border-radius: 0.25rem;
}

#basic-info-rent .body-styles .body-style input:checked + label {
  outline: 2px solid var(--primary-900);
  border-radius: 0.25rem;
}

@media (min-width: 400px) {
  #basic-info-rent .body-styles {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 500px) {
  #basic-info-rent .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 640px) {
  #basic-info-rent .body-styles {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  #basic-info-rent .body-styles .body-style img {
    width: 6.5rem;
  }
}
@media (min-width: 700px) {
  #basic-info-rent .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  #basic-info-rent .body-styles {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  #basic-info-rent .body-styles .body-style img {
    width: initial;
  }
}
@media (min-width: 1024px) {
  #basic-info-rent .body-styles {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
