#basic-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
#basic-info header p {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 1.125rem;
  line-height: 1.25;
  color: var(--primary-900);
}
#basic-info .row {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 0.75rem;
  row-gap: 0.5rem;
}
#basic-info .row .col {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}
#basic-info .body-style-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
#basic-info .body-style-row .body-style-heading {
  font-family: "Montserrat-Medium", "NotoSansArabic-Medium";
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
}
#basic-info .body-styles {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}
#basic-info .body-styles .body-style {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--primary-500);
  border-radius: 0.25rem;
  padding: 1rem 2rem 2rem 2rem;
  width: 100%;
  box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.1);
  cursor: pointer;
}
#basic-info .body-styles .body-style .body-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 0.25rem;
  height: 3rem;
}
#basic-info .body-styles .body-style img {
  pointer-events: none;
  width: 5.5rem;
}
#basic-info .body-styles .body-style input {
  display: none;
}
#basic-info .body-styles .body-style label {
  font-family: "Montserrat-Regular", "NotoSansArabic-Regular";
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  inset: 0;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0.75rem;
  border-radius: 0.25rem;
}

#basic-info .body-styles .body-style input:checked + label {
  outline: 2px solid var(--primary-900);
}

@media (min-width: 400px) {
  #basic-info .body-styles {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 500px) {
  #basic-info .row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 640px) {
  #basic-info .body-styles {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  #basic-info .body-styles .body-style img {
    width: 6.5rem;
  }
}
@media (min-width: 700px) {
  #basic-info .row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  #basic-info .body-styles {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  #basic-info .body-styles .body-style img {
    width: initial;
  }
}
@media (min-width: 1024px) {
  #basic-info .body-styles {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
